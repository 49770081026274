export function userShouldNotEnter(): boolean {
    const licenseIsValid = localStorage.getItem('licenseIsValid');
    const loginButtonClicked = localStorage.getItem('loginButtonClicked');
    const agreeToTerms = localStorage.getItem('agreeToTerms');

    return storageItemNotValid(licenseIsValid) || storageItemNotValid(loginButtonClicked) || storageItemNotValid(agreeToTerms);
}

function storageItemNotValid(storageItem: any): boolean {
    return storageItem === null || storageItem === undefined || storageItem === 'false';
}

import {useContext} from 'react';
import {ApplicationType} from '../model/ApplicationType';
import {getApplicationType} from './get-application-type';
import {ResultsContext} from '../../../store/results-context';

export function ShowAdditionalColumn(): boolean {
    const { innerWidth: width } = window;
    const resultsCtx = useContext(ResultsContext);

    return (getApplicationType() === ApplicationType.REGULAR && resultsCtx.checkedDressingsList.length === 3 && width >= 930) ||
        (getApplicationType() === ApplicationType.REGULAR && resultsCtx.checkedDressingsList.length === 2 && width >= 801);
}

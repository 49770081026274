import {DressingModel} from '../model/dressing.model';

export function createFilteredResults(
    dressingDataFull: DressingModel[],
    infectedWound: boolean,
    burnedWound: boolean,
    especiallyDryWound: boolean,
    woundWithNecrosis: boolean,
    maintainingMoistureEnvironment: boolean,
    adhesionToWound: boolean,
    combatingBiofilm: boolean,
    odorAbsorption: boolean,
    canBeTrimmed: boolean
): DressingModel[] {

    return dressingDataFull
        .filter((dressing: DressingModel) => infectedWound ? dressing.infectedWound : dressingDataFull)
        .filter((dressing: DressingModel) => burnedWound ? dressing.burnedWound : dressingDataFull)
        .filter((dressing: DressingModel) => especiallyDryWound ? dressing.especiallyDryWound : dressingDataFull)
        .filter((dressing: DressingModel) => woundWithNecrosis ? dressing.woundWithNecrosis : dressingDataFull)
        .filter((dressing: DressingModel) => maintainingMoistureEnvironment ? dressing.maintainingMoistureEnvironment : dressingDataFull)
        .filter((dressing: DressingModel) => adhesionToWound ? dressing.adhesionToWound : dressingDataFull)
        .filter((dressing: DressingModel) => combatingBiofilm ? dressing.combatingBiofilm : dressingDataFull)
        .filter((dressing: DressingModel) => odorAbsorption ? dressing.odorAbsorption : dressingDataFull)
        .filter((dressing: DressingModel) => canBeTrimmed ? dressing.canBeTrimmed : dressingDataFull);
}

import React, {useContext, useEffect} from 'react';
import {useNavigate} from 'react-router-dom';
import {ResultsContext} from '../../../store/results-context';
import SwitchComponent from '../../shared/components/switch/Switch.component';
import ButtonComponent from '../../shared/components/button/Button.component';
import NumberOfDressingsComponent from '../../shared/components/number-of-dressings/NumberOfDressings.component';
import {userShouldNotEnter} from '../../shared/utils/user-should-not-enter';

const FormWoundType: React.FC = () => {

    window.scrollTo(1, 1);

    const resultsCtx = useContext(ResultsContext);
    const navigate = useNavigate();

    const submitHandler = (event: React.FormEvent) => {
        event.preventDefault();
        navigate('/form-detailed-info');
    }

    useEffect(() => {
        if (userShouldNotEnter()) {
            navigate('/');
        }
    }, [navigate]);

    return (
        <div className="input-forms__wrapper">
            <form onSubmit={submitHandler} className="general_form__wrapper">
                <p className="general_form-header-text">Wybierz typ rany</p>

                <div className="general_switch-input__wrapper">
                    <SwitchComponent id="infectedWound"
                                     description="Rana zakażona"
                                     onCheckHandler={resultsCtx.onInfectedWoundClick}
                                     checked={resultsCtx.infectedWound}/>
                </div>

                <div className="general_switch-input__wrapper">
                    <SwitchComponent id="burnedWound"
                                     description="Oparzenie"
                                     onCheckHandler={resultsCtx.onBurnedWoundClick}
                                     checked={resultsCtx.burnedWound}/>
                </div>

                <div className="general_switch-input__wrapper">
                    <SwitchComponent id="especiallyWetWound"
                                     description="Szczególnie rana sucha"
                                     onCheckHandler={resultsCtx.onEspeciallyDryWoundClick}
                                     checked={resultsCtx.especiallyDryWound}/>
                </div>

                <div className="general_switch-input__wrapper">
                    <SwitchComponent id="woundWithNecrosis"
                                     description="Rana z martwicą"
                                     onCheckHandler={resultsCtx.onWoundWithNecrosisClick}
                                     checked={resultsCtx.woundWithNecrosis}/>
                </div>

                <div className="general_switch-input__wrapper">
                    <SwitchComponent id="maintainingMoistureEnvironment"
                                     description="Utrzymanie środowiska wilgotnego"
                                     onCheckHandler={resultsCtx.onMaintainingMoistureEnvironmentClick}
                                     checked={resultsCtx.maintainingMoistureEnvironment}/>
                </div>

                <NumberOfDressingsComponent dressings={resultsCtx.dressingsFiltered} />

                <div className="form-button-right__wrapper">
                    <ButtonComponent id="formWoundTypeSubmitButton" type="submit" value=">>"/>
                </div>
            </form>
        </div>

    );
}

export default FormWoundType;

import {DressingModel} from '../model/dressing.model';
import * as _ from 'underscore';

export function generateDressingStructures(dressingsFiltered: DressingModel[]): string[] {

    const dressingStructures: string[] = [];

    dressingsFiltered.forEach((dressing: DressingModel) => {

        dressing.structures.forEach((structure: string) => {
            if (structure !== '') {
                dressingStructures.push(structure);
            }
        });
    });

    return _.sortBy(_.unique(dressingStructures));
}

import React from 'react';
import * as Popover from '@radix-ui/react-popover';
import {Cross2Icon} from '@radix-ui/react-icons';
import './poprover.css';

const PopoverComponent: React.FC = () => {

    return (
        <Popover.Root>
            <Popover.Trigger asChild>
                <button className="icon-button" aria-label="Update dimensions">
                    <span>&#x3c; &#x2f;&#x3e;</span>
                </button>
            </Popover.Trigger>
            <Popover.Portal>
                <Popover.Content className="popover-content" sideOffset={5}>
                    <div className="popover-text">

                        <div className="medutools-img__wrapper">
                            <a href="https://medutools.pl" className="popover-branding" target="_blank" rel="noreferrer">
                                <img className="branding_img-size"
                                     alt="Polskie Towarzystwo Medycyny Rodzinnej"
                                     src={require('./../../../../assets/graphics/logoMedutools.png')}/>
                            </a>
                        </div>

                        <p className="medutools__text">Copyright 2023. <b>Wydawnictwo Interaktywne Medutools Sp. z o.o.</b></p>

                        <a href="https://medutools.pl" className="popover-branding" target="_blank" rel="noreferrer">
                            medutools.pl
                        </a>
                    </div>
                    <Popover.Close className="popover-close" aria-label="Close">
                        <Cross2Icon />
                    </Popover.Close>
                    <Popover.Arrow className="popover-arrow" />
                </Popover.Content>
            </Popover.Portal>
        </Popover.Root>
    );
}

export default PopoverComponent;

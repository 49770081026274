import React from 'react';
import * as Switch from '@radix-ui/react-switch';
import './switch.css';

type SwitchProps = {
    id: string;
    description: string;
    onCheckHandler: (event: any) => void;
    checked: boolean;
}

const SwitchComponent: React.FC<SwitchProps> = (props) => {
    return (
        <div className="switch_main-wrapper">
            <label className="switch-label" htmlFor={props.id}>
                {props.description}
            </label>
            <Switch.Root id={props.id}
                         className="switch_root"
                         onCheckedChange={props.onCheckHandler}
                         checked={props.checked}>
                <Switch.Thumb className="switch_thumb"/>
            </Switch.Root>
        </div>
    );
};

export default SwitchComponent;

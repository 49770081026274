import {DressingRawModel} from '../model/dressing-raw.model';
import {DressingModel} from '../model/dressing.model';
import {stringNotNull} from './string-null-check';
import {separateStringIntoArray} from './separate-string-into-array';
import {notNull} from './null-check';
import {separateStringIntoNumberArray} from './separate-string-into-number-array';

export function convertDressingRawModel(dressingRawModels: DressingRawModel[]): DressingModel[] {

    const dressingsArr: DressingModel[] = [];

    dressingRawModels.forEach((dressing: DressingRawModel) => {

        const structureString: string = stringNotNull(dressing.structure) ? dressing.structure : '';
        const structures: string[] = separateStringIntoArray(structureString);

        const tagsString: string = stringNotNull(dressing.tags) ? dressing.tags : '';
        const tags: string[] = separateStringIntoArray(tagsString);

        const activeSubstancesString: string = stringNotNull(dressing.activeSubstances) ? dressing.activeSubstances : '';
        const activeSubstances: string[] = separateStringIntoArray(activeSubstancesString);

        const typeOfWoundString: string = stringNotNull(dressing.typeOfWound) ? dressing.typeOfWound : '';
        const typeOfWound: string[] = separateStringIntoArray(typeOfWoundString);

        const whenToChangeString: string = notNull(dressing.whenToChange) ? dressing.whenToChange : '';
        const whenToChange: number[] = separateStringIntoNumberArray(whenToChangeString);

        dressingsArr.push({
            id: dressing.id,
            name: dressing.name,
            producer: dressing.producer,
            checked: false,
            disabled: false,
            structureFull: dressing.structureFull,
            structures: structures,
            tags: tags,
            primary: dressing.primary,
            secondary: dressing.secondary,
            action: dressing.action,
            activeSubstances: activeSubstances,
            typeOfWound: typeOfWound,
            infectedWound: dressing.infectedWound,
            burnedWound: dressing.burnedWound,
            especiallyDryWound: dressing.especiallyDryWound,
            woundWithNecrosis: dressing.woundWithNecrosis,
            exudingAbsorption: dressing.exudingAbsorption,
            maintainingMoistureEnvironment: dressing.maintainingMoistureEnvironment,
            adhesionToWound: dressing.adhesionToWound,
            canBeTrimmed: dressing.canBeTrimmed,
            combatingBiofilm: dressing.combatingBiofilm,
            odorAbsorption: dressing.odorAbsorption,
            requiresSecondaryDressing: dressing.requiresSecondaryDressing,
            additionalRequirements: dressing.additionalRequirements,
            whenToChangeDescription: dressing.whenToChangeDescription,
            whenToChange: whenToChange,
            requiresActivation: dressing.requiresActivation
        });
    });

    return dressingsArr;
}

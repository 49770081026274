import React from 'react';
import './button.css';

type ButtonProps = {
    id: string;
    type?: 'button' | 'submit';
    value: string;
    disabled?: boolean;
    onClick?: () => void;
    className?: string;
}

const ButtonComponent: React.FC<ButtonProps> = (props) => {
    return (
        <button id={props.id}
                type={props.type}
                data-testid="buttonTestId"
                className={props.className ? props.className : 'default-button'}
                disabled={props.disabled}
                onClick={props.onClick}>
            {props.value}
        </button>
    );
}

export default ButtonComponent;

import React from 'react';
import './dressingdetailedinfo.css';
import * as Collapsible from '@radix-ui/react-collapsible';
import {RowSpacingIcon, Cross2Icon} from '@radix-ui/react-icons';
import DressingSizePriceTableComponent from '../dressing-size-price-table/DressingSizePriceTable.component';
import TagsComponent from '../tags/Tags.component';
import {DressingModel} from '../../model/dressing.model';

type DressingDetailedInfoProps = {
    dressing: DressingModel;
}

const DressingDetailedInfoComponent: React.FC<DressingDetailedInfoProps> = (props: DressingDetailedInfoProps) => {

    const [open, setOpen] = React.useState(false);

    return (
        <Collapsible.Root className="dressing-detailed-info_collapsible-root" open={open} onOpenChange={setOpen}>

            <Collapsible.Trigger asChild>
                <div className="dressing-detailed-info_open-close-button__wrapper">
                    <button className="open-close__button">{open ? <Cross2Icon/> : <RowSpacingIcon/>}</button>
                </div>
            </Collapsible.Trigger>

            <Collapsible.Content className="dressing-detailed-info__collapsible">
                <div className="dressing-detailed-info_open-collapsible-prices__outer-wrapper">
                    <div className="dressing-detailed-info_open-collapsible-prices__inner-wrapper">
                        <DressingSizePriceTableComponent id={props.dressing.id}/>
                    </div>
                </div>
                <div className="dressing-detailed-info_open-collapsible-tags__wrapper">
                    <p className="results-all-table_section-headers__text">Ważne cechy:</p>
                    <TagsComponent tags={props.dressing.tags}/>
                </div>
            </Collapsible.Content>
        </Collapsible.Root>
    );

}

export default DressingDetailedInfoComponent;

import React from 'react';
import './selectbutton.css';

type Props = Omit<React.ComponentProps<"button">, "className"> & {};

const SelectButton = React.forwardRef<HTMLButtonElement, Props>(
    ({ children, ...props }, ref) => (
        <button ref={ref}{...props} className="select-button_main">
            {children}
        </button>
    )
);

SelectButton.displayName = 'Button';
export default SelectButton;

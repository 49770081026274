import React, {useContext, useEffect} from 'react';
import './singleresult.css';
import ButtonComponent from '../../shared/components/button/Button.component';
import {useNavigate} from 'react-router-dom';
import ResultsSingleTableComponent from '../../shared/components/results-single-table/ResultsSingleTable.component';
import {ResultsContext} from '../../../store/results-context';
import {notNull} from '../../shared/utils/null-check';
import {userShouldNotEnter} from '../../shared/utils/user-should-not-enter';

const SingleResult: React.FC = () => {

    window.scrollTo(1, 1);

    const navigate = useNavigate();
    const resultsCtx = useContext(ResultsContext);

    const goBackHandler = () => {
        navigate('/results');
    };

    useEffect(() => {
        if (userShouldNotEnter()) {
            navigate('/');
        }

        if (!notNull(resultsCtx.singleResultDressing)) {
            navigate('/');
        }
    }, [navigate, resultsCtx]);

    return (
        <div id="singleResultPage" className="single-result__wrapper">
            <div className="single-result__content">

                {resultsCtx.singleResultDressing &&
                    <div className="single-result_button__wrapper">
                        <ButtonComponent id="singleResultGoBackButton" value="<<" type="button" onClick={goBackHandler}/>
                    </div>
                }

                {resultsCtx.singleResultDressing &&
                    <div className="single-result_drug-header__wrapper">
                        <p className="single-result_drug-header-name__text">
                            {resultsCtx.singleResultDressing.name}
                        </p>
                        <p className="single-result_drug-header-company__text">
                            {resultsCtx.singleResultDressing.producer}
                        </p>
                    </div>
                }

                {resultsCtx.singleResultDressing &&
                    <div className="single-result_table__wrapper">
                        <ResultsSingleTableComponent dressing={resultsCtx.singleResultDressing}/>
                    </div>
                }

            </div>
        </div>
    );
}

export default SingleResult;

import React from 'react';
import './wordsvertical.css';

type WordsIndividualColumnProps = {
    data: string[];
}

const WordsVerticalComponent: React.FC<WordsIndividualColumnProps> = (props: WordsIndividualColumnProps) => {

    return (

        <div className="words__main-wrapper">
            {props.data.map(word => (<div key={word} className="word__single">{word}</div>))}
        </div>
    );
}

export default WordsVerticalComponent;

import React from 'react';
import './input.css';

type InputProps = {
    value: string;
    inputHandler: (event: any) => void;
}

const InputComponent: React.FC<InputProps> = ((props: InputProps) => {

    return (
        <input type="text"
               value={props.value}
               onChange={props.inputHandler}
               className="input-main__wrapper"
               placeholder="szukaj..." />
    )
});

export default InputComponent;

import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {BrowserRouter} from 'react-router-dom';
import ScrollToTop from './components/shared/hooks/ScrollToTop';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
      <BrowserRouter>
          <ScrollToTop>
            <App />
          </ScrollToTop>
      </BrowserRouter>
  </React.StrictMode>
);
reportWebVitals();

import * as _ from 'underscore';
export function separateStringIntoArray(dataString: string): string[] {

    const dataTemporary: string[] = dataString.split(";");
    const dataFinalArray: string[] = [];

    dataTemporary.forEach(element => {
        if (element.trim() !== '') {
            dataFinalArray.push(element.trim());
        }
    });

    return _.sortBy(dataFinalArray);
}

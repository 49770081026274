import React, {useContext} from 'react';
import {ResultsContext} from '../../../../store/results-context';
import {DressingPricingModel} from '../../model/dressing-pricing.model';
import {notNull} from '../../utils/null-check';
import './resultsdressingpricetable.css';

type DressingSizePriceTableProps = {
    id: string;
}

const DressingSizePriceTableComponent: React.FC<DressingSizePriceTableProps> = (props: DressingSizePriceTableProps) => {

    const resultsCtx = useContext(ResultsContext);
    const dressing = resultsCtx.dressingPricingDataFull.filter((dressing: DressingPricingModel) => dressing.id === props.id)[0];

    function sizeExists(size1: string, size2: string, size3: string, size4: string, size5: string, size6: string, size7: string,
                        size8: string, size9: string, size10: string, size11: string, size12: string): boolean {
        return notNull(size1) || notNull(size2) || notNull(size3) || notNull(size4) || notNull(size5) || notNull(size5) ||
            notNull(size6) || notNull(size7) || notNull(size8) || notNull(size9) || notNull(size10) || notNull(size11) ||
            notNull(size12);
    }

    function size100Exists(): boolean {
        return sizeExists(dressing.size1_100, dressing.size2_100, dressing.size3_100, dressing.size4_100, dressing.size5_100,
            dressing.size6_100, dressing.size7_100, dressing.size8_100, dressing.size9_100, dressing.size10_100,
            dressing.size11_100, dressing.size12_100);
    }

    function size30Exists(): boolean {
        return sizeExists(dressing.size1_30, dressing.size2_30, dressing.size3_30, dressing.size4_30, dressing.size5_30,
            dressing.size6_30, dressing.size7_30, dressing.size8_30, dressing.size9_30, dressing.size10_30,
            dressing.size11_30, dressing.size12_30);
    }

    function sizeBExists(): boolean {
        return sizeExists(dressing.size1_B, dressing.size2_B, dressing.size3_B, dressing.size4_B, dressing.size5_B,
            dressing.size6_B, dressing.size7_B, dressing.size8_B, dressing.size9_B, dressing.size10_B,
            dressing.size11_B, dressing.size12_B);
    }

    function sizeSuggestedExists(): boolean {
        return sizeExists(dressing.size1_suggested, dressing.size2_suggested, dressing.size3_suggested, dressing.size4_suggested, dressing.size5_suggested,
            dressing.size6_suggested, dressing.size7_suggested, dressing.size8_suggested, dressing.size9_suggested, dressing.size10_suggested,
            dressing.size11_suggested, dressing.size12_suggested);
    }

    return (
        <table id="resultsDressingPriceTable">
            <thead>
            <tr>
                <th>Rozmiar</th>

                {size100Exists() &&
                    <th>100%</th>
                }
                {size30Exists() &&
                    <th>30%</th>
                }
                {sizeBExists() &&
                    <th>B</th>
                }
                {sizeSuggestedExists() &&
                    <th>Cena sugerowana</th>
                }
            </tr>
            </thead>
            <tbody>
            {notNull(dressing.size1) &&
                <tr>
                    <td>{dressing.size1}</td>
                    {size100Exists() && <td>{dressing.size1_100} {notNull(dressing.size1_suggested) ? '' : 'zł'}</td>}
                    {size30Exists() && <td>{dressing.size1_30} {notNull(dressing.size1_suggested) ? '' : 'zł'}</td>}
                    {sizeBExists() && <td>{dressing.size1_B} {notNull(dressing.size1_suggested) ? '' : 'zł'}</td>}
                    {sizeSuggestedExists() && <td>{dressing.size1_suggested}</td>}
                </tr>
            }
            {notNull(dressing.size2) &&
                <tr>
                    <td>{dressing.size2}</td>
                    {size100Exists() && <td>{dressing.size2_100} {notNull(dressing.size2_suggested) ? '' : 'zł'}</td>}
                    {size30Exists() && <td>{dressing.size2_30} {notNull(dressing.size2_suggested) ? '' : 'zł'}</td>}
                    {sizeBExists() && <td>{dressing.size2_B} {notNull(dressing.size2_suggested) ? '' : 'zł'}</td>}
                    {sizeSuggestedExists() && <td>{dressing.size2_suggested}</td>}
                </tr>
            }
            {notNull(dressing.size3) &&
                <tr>
                    <td>{dressing.size3}</td>
                    {size100Exists() && <td>{dressing.size3_100} {notNull(dressing.size3_suggested) ? '' : 'zł'}</td>}
                    {size30Exists() && <td>{dressing.size3_30} {notNull(dressing.size3_suggested) ? '' : 'zł'}</td>}
                    {sizeBExists() && <td>{dressing.size3_B} {notNull(dressing.size3_suggested) ? '' : 'zł'}</td>}
                    {sizeSuggestedExists() && <td>{dressing.size3_suggested}</td>}
                </tr>
            }
            {notNull(dressing.size4) &&
                <tr>
                    <td>{dressing.size4}</td>
                    {size100Exists() && <td>{dressing.size4_100} {notNull(dressing.size4_suggested) ? '' : 'zł'}</td>}
                    {size30Exists() && <td>{dressing.size4_30} {notNull(dressing.size4_suggested) ? '' : 'zł'}</td>}
                    {sizeBExists() && <td>{dressing.size4_B} {notNull(dressing.size4_suggested) ? '' : 'zł'}</td>}
                    {sizeSuggestedExists() && <td>{dressing.size4_suggested}</td>}
                </tr>
            }
            {notNull(dressing.size5) &&
                <tr>
                    <td>{dressing.size5}</td>
                    {size100Exists() && <td>{dressing.size5_100} {notNull(dressing.size5_suggested) ? '' : 'zł'}</td>}
                    {size30Exists() && <td>{dressing.size5_30} {notNull(dressing.size5_suggested) ? '' : 'zł'}</td>}
                    {sizeBExists() && <td>{dressing.size5_B} {notNull(dressing.size5_suggested) ? '' : 'zł'}</td>}
                    {sizeSuggestedExists() && <td>{dressing.size5_suggested}</td>}
                </tr>
            }
            {notNull(dressing.size6) &&
                <tr>
                    <td>{dressing.size6}</td>
                    {size100Exists() && <td>{dressing.size6_100} {notNull(dressing.size6_suggested) ? '' : 'zł'}</td>}
                    {size30Exists() && <td>{dressing.size6_30} {notNull(dressing.size6_suggested) ? '' : 'zł'}</td>}
                    {sizeBExists() && <td>{dressing.size6_B} {notNull(dressing.size6_suggested) ? '' : 'zł'}</td>}
                    {sizeSuggestedExists() && <td>{dressing.size6_suggested}</td>}
                </tr>
            }
            {notNull(dressing.size7) &&
                <tr>
                    <td>{dressing.size7}</td>
                    {size100Exists() && <td>{dressing.size7_100} {notNull(dressing.size7_suggested) ? '' : 'zł'}</td>}
                    {size30Exists() && <td>{dressing.size7_30} {notNull(dressing.size7_suggested) ? '' : 'zł'}</td>}
                    {sizeBExists() && <td>{dressing.size7_B} {notNull(dressing.size7_suggested) ? '' : 'zł'}</td>}
                    {sizeSuggestedExists() && <td>{dressing.size7_suggested}</td>}
                </tr>
            }
            {notNull(dressing.size8) &&
                <tr>
                    <td>{dressing.size8}</td>
                    {size100Exists() && <td>{dressing.size8_100} {notNull(dressing.size8_suggested) ? '' : 'zł'}</td>}
                    {size30Exists() && <td>{dressing.size8_30} {notNull(dressing.size8_suggested) ? '' : 'zł'}</td>}
                    {sizeBExists() && <td>{dressing.size8_B} {notNull(dressing.size8_suggested) ? '' : 'zł'}</td>}
                    {sizeSuggestedExists() && <td>{dressing.size8_suggested}</td>}
                </tr>
            }
            {notNull(dressing.size9) &&
                <tr>
                    <td>{dressing.size9}</td>
                    {size100Exists() && <td>{dressing.size9_100} {notNull(dressing.size9_suggested) ? '' : 'zł'}</td>}
                    {size30Exists() && <td>{dressing.size9_30} {notNull(dressing.size9_suggested) ? '' : 'zł'}</td>}
                    {sizeBExists() && <td>{dressing.size9_B} {notNull(dressing.size9_suggested) ? '' : 'zł'}</td>}
                    {sizeSuggestedExists() && <td>{dressing.size9_suggested}</td>}
                </tr>
            }
            {notNull(dressing.size10) &&
                <tr>
                    <td>{dressing.size10}</td>
                    {size100Exists() && <td>{dressing.size10_100} {notNull(dressing.size10_suggested) ? '' : 'zł'}</td>}
                    {size30Exists() && <td>{dressing.size10_30} {notNull(dressing.size10_suggested) ? '' : 'zł'}</td>}
                    {sizeBExists() && <td>{dressing.size10_B} {notNull(dressing.size10_suggested) ? '' : 'zł'}</td>}
                    {sizeSuggestedExists() && <td>{dressing.size10_suggested}</td>}
                </tr>
            }
            {notNull(dressing.size11) &&
                <tr>
                    <td>{dressing.size11}</td>
                    {size100Exists() && <td>{dressing.size11_100} {notNull(dressing.size11_suggested) ? '' : 'zł'}</td>}
                    {size30Exists() && <td>{dressing.size11_30} {notNull(dressing.size11_suggested) ? '' : 'zł'}</td>}
                    {sizeBExists() && <td>{dressing.size11_B} {notNull(dressing.size11_suggested) ? '' : 'zł'}</td>}
                    {sizeSuggestedExists() && <td>{dressing.size11_suggested}</td>}
                </tr>
            }
            {notNull(dressing.size12) &&
                <tr>
                    <td>{dressing.size12}</td>
                    {size100Exists() && <td>{dressing.size12_100} {notNull(dressing.size12_suggested) ? '' : 'zł'}</td>}
                    {size30Exists()  && <td>{dressing.size12_30} {notNull(dressing.size12_suggested) ? '' : 'zł'}</td>}
                    {sizeBExists() && <td>{dressing.size12_B} {notNull(dressing.size12_suggested) ? '' : 'zł'}</td>}
                    {sizeSuggestedExists() && <td>{dressing.size12_suggested}</td>}
                </tr>
            }
            </tbody>

        </table>
    );
}

export default DressingSizePriceTableComponent;

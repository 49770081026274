import React from 'react';
import * as SelectPrimitive from '@radix-ui/react-select';
import {CheckIcon, ChevronDownIcon, ChevronUpIcon} from '@radix-ui/react-icons';
import SelectButton from '../selectbutton/SelectButton.component';
import {DayModel} from '../../model/day.model';
import './select.css';

type SelectDayProps = {
    value: string;
    type: string;
    onValueChange: (value: any) => void;
    options: DayModel[];
};

const SelectDayComponent: React.FC<SelectDayProps> = (props: SelectDayProps) => {
    return (
        <SelectPrimitive.Root value={props.value !== 'no-option' ? props.value : 'no-option'} onValueChange={props.onValueChange}>
            <SelectPrimitive.Trigger className="select-trigger" asChild>
                <SelectButton>
                    <SelectPrimitive.Value />
                </SelectButton>
            </SelectPrimitive.Trigger>
            <SelectPrimitive.Content className="select-content">
                <SelectPrimitive.ScrollUpButton className="select-scroll-button">
                    <ChevronUpIcon />
                </SelectPrimitive.ScrollUpButton>
                <SelectPrimitive.Viewport className="select-viewport">

                    <SelectPrimitive.Group>
                        <SelectPrimitive.Item
                            key="no-option"
                            value="no-option"
                            disabled={true}
                            className="default-option">
                            <SelectPrimitive.ItemText>{props.type}</SelectPrimitive.ItemText>
                        </SelectPrimitive.Item>
                    </SelectPrimitive.Group>

                    <SelectPrimitive.Separator className="select-separator" />

                    <SelectPrimitive.Group>
                        {props.options.map(
                            (item, i) => (
                                <SelectPrimitive.Item
                                    key={item.id}
                                    value={item.id.toString()}
                                    disabled={item.disabled}
                                    className="select-item">
                                    <SelectPrimitive.ItemText>{item.value}</SelectPrimitive.ItemText>
                                    <SelectPrimitive.ItemIndicator className="select-item-indicator">
                                        <CheckIcon />
                                    </SelectPrimitive.ItemIndicator>
                                </SelectPrimitive.Item>
                            )
                        )}
                    </SelectPrimitive.Group>
                </SelectPrimitive.Viewport>
                <SelectPrimitive.ScrollDownButton className="select-scroll-button">
                    <ChevronDownIcon />
                </SelectPrimitive.ScrollDownButton>
            </SelectPrimitive.Content>
        </SelectPrimitive.Root>
    );
};

export { SelectDayComponent };

import {DressingModel} from '../model/dressing.model';
import {dressingContainsSearchedText} from './dressing-contains-searched-text';
import {whenToChangeIsWithinSelectedRange} from './when-to-change-is-within-selected-range';
import * as _ from 'underscore';

export function getFullyFilteredResults (
    dressings: DressingModel[],
    searchedText: string,
    sortedAlphabetically: boolean,
    filtersRequiresActivationYes: boolean,
    filtersRequiresActivationNo: boolean,
    filtersRequiresSecondaryDressingYes: boolean,
    filtersRequiresSecondaryDressingNo: boolean,
    filtersPrimaryDressing: boolean,
    filtersSecondaryDressing: boolean,
    activeSubstance: string,
    structure: string,
    dayFrom: string,
    dayTo: string): DressingModel[] {

    const results = dressings
        .filter((dressing: DressingModel) => dressingContainsSearchedText(dressing, searchedText))
        .filter((dressing: DressingModel) => filtersRequiresActivationYes ? dressing.requiresActivation : dressings)
        .filter((dressing: DressingModel) => filtersRequiresActivationNo ? !dressing.requiresActivation : dressings)
        .filter((dressing: DressingModel) => filtersRequiresSecondaryDressingYes ? dressing.requiresSecondaryDressing : dressings)
        .filter((dressing: DressingModel) => filtersRequiresSecondaryDressingNo ? !dressing.requiresSecondaryDressing : dressings)
        .filter((dressing: DressingModel) => filtersPrimaryDressing ? dressing.primary : dressings)
        .filter((dressing: DressingModel) => filtersSecondaryDressing ? dressing.secondary : dressings)
        .filter((dressing: DressingModel) => activeSubstance === 'no-option' ? dressings : _.contains(dressing.activeSubstances, activeSubstance))
        .filter((dressing: DressingModel) => structure === 'no-option' ? dressings : _.contains(dressing.structures, structure))
        .filter((dressing: DressingModel) => whenToChangeIsWithinSelectedRange(dressing.whenToChange, dayFrom, dayTo));

    return sortedAlphabetically ? _.sortBy(results, "name") : _.sortBy(results, "name").reverse();
}

import React from 'react';
import './numberofdressings.css';
import {DressingModel} from '../../model/dressing.model';

type NumberOfDressingsProps = {
    dressings: DressingModel[];
}

const NumberOfDressings: React.FC<NumberOfDressingsProps> = (props: NumberOfDressingsProps) => {
    return (
        <div className="number-of-dressings__main-wrapper">
            znalezione opatrunki: {props.dressings.length}
        </div>
    );
}

export default NumberOfDressings;

import React, {useContext} from 'react';
import './seemore.css';
import {useNavigate} from 'react-router-dom';
import {ResultsContext} from '../../../../store/results-context';
import {DressingModel} from '../../model/dressing.model';
import {saveSingleDressingToDatabase} from '../../utils/save-single-dressing-to-database';

type SeeMoreProps = {
    id: string;
}

const SeeMoreComponent: React.FC<SeeMoreProps> = (props: SeeMoreProps) => {

    const navigate = useNavigate();
    const resultsCtx = useContext(ResultsContext);

    const seeMoreClickHandler = () => {
        resultsCtx.singleResultDressing = resultsCtx.dressingsFiltered
            .filter((dressing: DressingModel) => dressing.id === props.id)[0];
        saveSingleDressingToDatabase(resultsCtx);
        navigate('/single');
    };


    return (
        <div className="see-more__main-wrapper" onClick={seeMoreClickHandler}>
            <span>
                więcej &#62;&#62;
            </span>
        </div>
    );
}

export default SeeMoreComponent;

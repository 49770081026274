export function saveDressingsComparedToDatabase(resultsCtx: any): void {
    fetch('https://medutoolsbe.herokuapp.com/api/v1/dressings/compare', {
        method: 'POST',
        body: JSON.stringify({
            userId: resultsCtx.userId,
            dressingOneId: resultsCtx.checkedDressingsList[0].id,
            dressingOneName: resultsCtx.checkedDressingsList[0].name,
            dressingOneProducer: resultsCtx.checkedDressingsList[0].producer,
            dressingTwoId: resultsCtx.checkedDressingsList[1].id,
            dressingTwoName: resultsCtx.checkedDressingsList[1].name,
            dressingTwoProducer: resultsCtx.checkedDressingsList[1].producer,
            dressingThreeId: resultsCtx.checkedDressingsList[2] ? resultsCtx.checkedDressingsList[2].id : '',
            dressingThreeName: resultsCtx.checkedDressingsList[2] ? resultsCtx.checkedDressingsList[2].name : '',
            dressingThreeProducer: resultsCtx.checkedDressingsList[2] ? resultsCtx.checkedDressingsList[2].producer : '',
        }),
        headers: {
            'Content-Type' : 'application/json'
        }
    }).then(() => {})
        .catch((err) => {});
}

import {DayModel} from '../model/day.model';
import * as _ from 'underscore';

export function blockPreviousDays(chosenDayFrom: number, daysList: DayModel[]): DayModel[] {

    const daysListFinal: DayModel[] = [];

    daysList.forEach((day: DayModel) => {
        if (day.id >= chosenDayFrom) {
            daysListFinal.push({
                id: day.id,
                value: day.value,
                disabled: false
            });
        }
    });

    return _.sortBy(daysListFinal, 'id');
}

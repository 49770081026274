import React, {ChangeEvent} from 'react';
import './medicallicensenumber.css';
import MaskedInput from 'react-text-mask'

type InputMaskProps = {
    value: string;
    mask: any;
    placeholder: string;
    onChange: (event: ChangeEvent<HTMLInputElement>) => void;
}

const MedicalLicenseNumberInput: React.FC<InputMaskProps> = (props: InputMaskProps) => {

    return (
        <MaskedInput
            className="medical-license-number-input"
            placeholder={props.placeholder}
            mask={props.mask}
            value={props.value}
            onChange={props.onChange}
        />
    );
}
export default MedicalLicenseNumberInput;

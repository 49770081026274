import React from 'react';
import './resultssingletable.css';
import DressingSizePriceTableComponent from '../dressing-size-price-table/DressingSizePriceTable.component';
import {DressingModel} from '../../model/dressing.model';
import WordsVerticalComponent from '../words-vertical/WordsVertical.component';
import TagsComponent from '../tags/Tags.component';

type ResultsSingleTableProps = {
    dressing: DressingModel;
}

const ResultsSingleTableComponent: React.FC<ResultsSingleTableProps> = (props: ResultsSingleTableProps) => {

    return (

        <div className="results-single-table__main-wrapper">

            <div className="results-single-table_content__wrapper">

                <div className="results-single-table_element-wrapper">
                    <div className="results-single-table_element-header__wrapper">
                        <span className="results-single-table_element-header__text">Opis</span>
                    </div>
                    <div className="results-single-table_element-content__wrapper">
                        <div className="results-single-table_structure-tags__wrapper">
                            {props.dressing.structureFull}
                            <TagsComponent tags={props.dressing.structures} />
                        </div>
                    </div>
                </div>

                <div className="results-single-table_element-wrapper">
                    <div className="results-single-table_element-header__wrapper">
                        <span className="results-single-table_element-header__text">Ważne cechy</span>
                    </div>
                    <div className="results-single-table_element-content__wrapper">
                        <WordsVerticalComponent data={props.dressing.tags} />
                    </div>
                </div>

                <div className="results-single-table_element-wrapper">
                    <div className="results-single-table_element-header__wrapper">
                        <span className="results-single-table_element-header__text">Działanie</span>
                    </div>
                    <div className="results-single-table_element-content__wrapper">
                        {props.dressing.action}
                    </div>
                </div>

                <div className="results-single-table_element-wrapper">
                    <div className="results-single-table_element-header__wrapper">
                        <span className="results-single-table_element-header__text">Jakie rany?</span>
                    </div>
                    <div className="results-single-table_element-content__wrapper">
                        <WordsVerticalComponent data={props.dressing.typeOfWound} />
                    </div>
                </div>

                <div className="results-single-table_element-wrapper">
                    <div className="results-single-table_element-header__wrapper">
                        <span className="results-single-table_element-header__text">Substancje czynne</span>
                    </div>
                    <div className="results-single-table_element-content__wrapper">
                        <WordsVerticalComponent data={props.dressing.activeSubstances} />
                    </div>
                </div>

                <div className="results-single-table_element-wrapper">
                    <div className="results-single-table_element-header__wrapper">
                        <span className="results-single-table_element-header__text">Kiedy zmieniać?</span>
                    </div>
                    <div className="results-single-table_element-content__wrapper">
                        <div className="results_center-text-on-small-screen">
                            {props.dressing.whenToChangeDescription}
                        </div>
                    </div>
                </div>

                <div className="results-single-table_element-wrapper">
                    <div className="results-single-table_element-header__wrapper">
                        <span className="results-single-table_element-header__text">Wymaga opatrunku wtórnego</span>
                    </div>
                    <div className="results-single-table_element-content__wrapper">
                        <div className="results_center-text-on-small-screen">
                            {props.dressing.requiresSecondaryDressing ? 'tak' : 'nie'}
                        </div>
                    </div>
                </div>

                <div className="results-single-table_element-wrapper">
                    <div className="results-single-table_element-header__wrapper">
                        <span className="results-single-table_element-header__text">Wymaga aktywacji</span>
                    </div>
                    <div className="results-single-table_element-content__wrapper">
                        <div className="results_center-text-on-small-screen">
                            {props.dressing.requiresActivation ? 'tak' : 'nie'}
                        </div>
                    </div>
                </div>

                <div className="results-single-table_element-wrapper">
                    <div className="results-single-table_element-header__wrapper">
                        <span className="results-single-table_element-header__text">Dodatkowe wymagania</span>
                    </div>
                    <div className="results-single-table_element-content__wrapper">

                        {props.dressing.additionalRequirements === 'brak' &&
                            <div className="results_center-text-on-small-screen">
                                {props.dressing.additionalRequirements}
                            </div>
                        }

                        {props.dressing.additionalRequirements !== 'brak' &&
                            <div>{props.dressing.additionalRequirements}</div>
                        }

                    </div>
                </div>

            </div>

            <div className="results-single-table_prices__wrapper">

                <div className="results-single-table_prices-header__wrapper">
                    <span>Wymiary i cena:</span>
                </div>

                <div className="results-single-table_prices-content__wrapper">
                    <div className="results-single-table_prices-content__inner-wrapper">
                        <DressingSizePriceTableComponent id={props.dressing.id} />
                    </div>
                </div>
            </div>

        </div>
    );
}

export default ResultsSingleTableComponent;

import React, {ReactNode, useLayoutEffect} from 'react';
import {useLocation} from 'react-router-dom';

const ScrollToTop: React.FC<{ children: ReactNode }> = ({children}) => {

    const pathname = useLocation();

    useLayoutEffect(() => {
        window.scrollTo(0, 1);
    }, [pathname]);

    return <>{children}</>;
};

export default ScrollToTop;

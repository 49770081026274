import React, {ChangeEvent, useContext, useEffect, useState} from 'react';
import './login.css';
import {useNavigate} from 'react-router-dom';
import ButtonComponent from '../../shared/components/button/Button.component';
import MedicalLicenseNumberInput from '../../shared/components/medical-license-number/MedicalLicenseNumberInput';
import {validateDoctorsMedicalLicenseNumber} from '../../shared/utils/validate-doctors-medical-license-number';
import {validateNursesMedicalLicenseNumber} from '../../shared/utils/validate-nurses-medical-license-number';
import {SelectComponent} from '../../shared/components/select/Select.component';
import CheckboxComponent from '../../shared/components/checkbox/Checkbox.component';
import {ResultsContext} from '../../../store/results-context';
import {stringNotNull} from '../../shared/utils/string-null-check';
import {prepareLicenseNumberFormat} from '../../shared/utils/prepare-license-number-format';

const Login: React.FC = () => {

    const navigate = useNavigate();
    const resultsCtx = useContext(ResultsContext);

    const [licenseNumber, setLicenseNumber] = useState<string>('');
    const [loginButtonDisabled, setLoginButtonDisabled] = useState<boolean>(!(localStorage.getItem('licenseIsValid') === 'true' && resultsCtx.agreeToTerms));
    const [profession, setProfession] = useState<string>(resultsCtx.profession);
    const [agreeToTerms, setAgreeToTerms] = useState<boolean>(resultsCtx.agreeToTerms);

    const doctorsRegex = [/[0-9]/, ' ', /[0-9]/, ' ', /[0-9]/, ' ', /[0-9]/, ' ', /[0-9]/, ' ', /[0-9]/, ' ', /[0-9]/];
    const nursesRegex = [/[0-9]/, ' ', /[0-9]/, ' ', /[0-9]/, ' ', /[0-9]/, ' ', /[0-9]/, ' ', /[0-9]/, ' ', /[0-9]/, ' ', /[A-Za-z]/];

    const buttonHandler = () => {
        localStorage.setItem('loginButtonClicked', 'true');
        navigate('/form-wound-type');
    }

    const licenseInputHandler = (event: ChangeEvent<HTMLInputElement>) => {

        const licenseNumber = prepareLicenseNumberFormat(event);
        setLicenseNumber(licenseNumber);
        resultsCtx.onLicenseNumberChange(licenseNumber);

        if (validateDoctorsMedicalLicenseNumber(licenseNumber) || validateNursesMedicalLicenseNumber(licenseNumber)) {
            localStorage.setItem('licenseIsValid', 'true');
            if (agreeToTerms) setLoginButtonDisabled(false);
        } else {
            localStorage.setItem('licenseIsValid', 'false');
            setLoginButtonDisabled(true);
        }
    }

    const selectProfessionHandler = (profession: string) => {
        setProfession(profession);
        setLicenseNumber('');
        resultsCtx.onProfessionChange(profession);
        resultsCtx.onLicenseNumberChange('');
        setLoginButtonDisabled(true);
        localStorage.setItem('licenseIsValid', 'false');
        localStorage.setItem('loginButtonClicked', 'false');
    }

    const checkboxHandler = (state: boolean) => {

        setAgreeToTerms(state);
        resultsCtx.onAgreeToTermsChange(state);
        localStorage.setItem('agreeToTerms', state ? 'true' : 'false');

        if (state) {
            if ((profession === 'lekarzem' && validateDoctorsMedicalLicenseNumber(resultsCtx.licenseNumber)) ||
                (profession === 'pielęgniarką' && validateNursesMedicalLicenseNumber(resultsCtx.licenseNumber))) {
                setLoginButtonDisabled(false);
            }
        } else {
            setLoginButtonDisabled(true);
        }
    }

    useEffect(() => {
    }, [
        licenseNumber,
        loginButtonDisabled,
        profession,
        agreeToTerms
    ]);

    return (
        <div className="login__main-wrapper">

            <div className="login__inner-wrapper">

                <div className="login_label-input__wrapper">
                    <div className="login_label__wrapper">
                        <div className="login_label__inner-wrapper">
                            Jestem:
                        </div>
                    </div>
                    <div className="login_input__wrapper">
                        <div className="login_select__wrapper">
                            <SelectComponent value={profession}
                                             showChooseOption={false}
                                             onValueChange={selectProfessionHandler}
                                             data1={['lekarzem', 'pielęgniarką']}/>
                        </div>
                    </div>
                </div>

                <div className="login_label-input__wrapper">
                    <div className="login_label__wrapper">
                        <div className="login_label__inner-wrapper">
                            Numer PWZ:
                        </div>
                    </div>
                    <div className="login_input__wrapper">
                        <MedicalLicenseNumberInput
                            value={stringNotNull(resultsCtx.licenseNumber) ? resultsCtx.licenseNumber : licenseNumber}
                            mask={profession === 'lekarzem' ? doctorsRegex : nursesRegex}
                            placeholder={profession === 'lekarzem' ? "_ _ _ _ _ _ _" : "_ _ _ _ _ _ _ _"}
                            onChange={licenseInputHandler}/>
                    </div>
                </div>
                <div className="login_claim__wrapper">
                    Wpisując numer wykonywania zawodu oświadczam, że z zawodu
                    jestem {profession === 'lekarzem' ? 'lekarzem' : 'pielęgniarką'}.
                </div>

                <div className="login_checkbox__wrapper">
                    <CheckboxComponent id="loginRegulationsAgreementCheckbox"
                                       value="checkboxValue"
                                       showRegulationsValue={true}
                                       onCheckHandler={checkboxHandler}
                                       checked={agreeToTerms}/>
                </div>

                <div className="login_button__wrapper">
                    <ButtonComponent id="loginButton"
                                     value="wejdź >>"
                                     className="login-button"
                                     disabled={loginButtonDisabled}
                                     onClick={buttonHandler}/>
                </div>
            </div>
        </div>
    );

}

export default Login;

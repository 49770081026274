import React, {useContext, useEffect} from 'react';
import './results.css';
import {useNavigate} from 'react-router-dom';
import {ResultsContext} from '../../../store/results-context';
import ButtonComponent from '../../shared/components/button/Button.component';
import PopoverComponent from '../../shared/components/popover/Popover.component';
import BrandingComponent from '../../shared/components/branding/Branding.component';
import YesNoCheckboxComponent from '../../shared/components/yes-no-checkbox/YesNoCheckbox.component';
import {SelectComponent} from '../../shared/components/select/Select.component';
import {SelectDayComponent} from '../../shared/components/select/SelectDayComponent';
import {DayModel} from '../../shared/model/day.model';
import InputComponent from '../../shared/components/input/Input.component';
import ResultsAllTableComponent from '../../shared/components/results-all-table/ResultsAllTable.component';
import {DressingModel} from '../../shared/model/dressing.model';
import NumberOfDressingsComponent from '../../shared/components/number-of-dressings/NumberOfDressings.component';
import {getFullyFilteredResults} from '../../shared/utils/get-fully-filtered-results';
import {saveDressingsComparedToDatabase} from '../../shared/utils/save-dressings-compared-to-database';
import {userShouldNotEnter} from '../../shared/utils/user-should-not-enter';

type ResultsProps = {
    daysFrom: DayModel[];
    daysTo: DayModel[];
};

const Results: React.FC<ResultsProps> = (props: ResultsProps) => {

    const navigate = useNavigate();
    const resultsCtx = useContext(ResultsContext);

    const goBackHandler = () => {
        clearCheckedDressings();
        navigate('/form-detailed-info');
    };

    function clearCheckedDressings(): void {
        resultsCtx.dressingsAdditionallyFiltered.forEach((dressing: DressingModel) => {
            dressing.checked = false;
            dressing.disabled = false;
        });
        resultsCtx.setCompareDressingsButtonDisabled(true);
    }

    const resetFiltersHandler = () => {
        resultsCtx.onFiltersRequiresActivationYesClick(false);
        resultsCtx.onFiltersRequiresActivationNoClick(false);
        resultsCtx.onFiltersRequiresSecondaryYesClick(false);
        resultsCtx.onFiltersRequiresSecondaryDressingNoClick(false);
        resultsCtx.onFiltersPrimaryDressingClick(false);
        resultsCtx.onFiltersSecondaryDressingClick(false);
        resultsCtx.onFiltersSelectedActiveSubstanceClick('no-option');
        resultsCtx.onFiltersSelectedStructure('no-option');
        resultsCtx.onSelectedDaysResetButtonClick();
        resultsCtx.setActiveFilters(false);
        resultsCtx.onSearchedTextChange('');
        resultsCtx.setDressingsAdditionallyFiltered(resultsCtx.dressingsFiltered);
        resultsCtx.setCompareDressingsButtonDisabled(true);
    };

    const compareButtonHandler = () => {
        saveDressingsComparedToDatabase(resultsCtx);
        if (resultsCtx.checkedDressingsList.length === 3) {
            navigate('/triple');
        }
        if (resultsCtx.checkedDressingsList.length === 2) {
            navigate('/double');
        }
    };

    const inputContentHandler = (event: any) => {
        resultsCtx.onSearchedTextChange(event.target.value);

        const dressingsAdditionallyFiltered = getFullyFilteredResults(
            resultsCtx.dressingsFiltered,
            event.target.value,
            resultsCtx.sortedAlphabetically,
            resultsCtx.filtersRequiresActivationYes,
            resultsCtx.filtersRequiresActivationNo,
            resultsCtx.filtersRequiresSecondaryDressingYes,
            resultsCtx.filtersRequiresSecondaryDressingNo,
            resultsCtx.filtersPrimaryDressing,
            resultsCtx.filtersSecondaryDressing,
            resultsCtx.filtersSelectedActiveSubstance,
            resultsCtx.filtersSelectedStructure,
            resultsCtx.filtersSelectedDayFrom,
            resultsCtx.filtersSelectedDayTo
        );

        resultsCtx.setDressingsAdditionallyFiltered(dressingsAdditionallyFiltered);
    };

    useEffect(() => {
        if (userShouldNotEnter()) {
            navigate('/');
        }

        if (resultsCtx.dressingsFiltered.length === 0) {
            setTimeout(() => navigate('/'), 3000);
        }
    }, [resultsCtx, navigate]);

    return (
        <div className="results__wrapper">
            <div className="results__content">
                <div className="results_button__wrapper">
                    <ButtonComponent id="resultsGoBackButton" value="<<" type="button" onClick={goBackHandler}/>
                </div>
                {resultsCtx.dressingsFiltered.length === 0 &&
                    <div className="results__no-results">
                        <p>Nie znaleziono wyników.</p>
                        <p>Spróbuj rozszerzyć kryteria wyszukiwania.</p>
                    </div>
                }

                {resultsCtx.dressingsFiltered.length !== 0 &&

                    <div>
                        <div className="results_filters__outer-wrapper">
                            <div className="results_filters-header__wrapper">
                                <div className="results_reset-button__wrapper">
                                    <ButtonComponent id="resultsResetButton" value="wyczyść x" type="button"
                                                     onClick={resetFiltersHandler}/>
                                </div>
                                <div className="results_alert__wrapper">
                                    {resultsCtx.activeFilters &&
                                        <div className="results_alert-text__wrapper">
                                            Uwaga! Filtry aktywne!
                                        </div>
                                    }
                                </div>
                            </div>
                            <div className="results_filters-content__wrapper">
                                <div className="results_filters-content-right-left__wrapper">

                                    <div className="results_filters-single-filter__wrapper">
                                        <div className="results_filters-single-filter-label__wrapper">
                                            <span className="results_label">Wymaga aktywacji</span>
                                        </div>
                                        <div className="results_filters-single-filter-content__wrapper">
                                            <div className="results_filters-single-filter-content__inner-wrapper">
                                                <div className="results_filters-single-filter-content__left-wrapper">
                                                    <YesNoCheckboxComponent id="requiresActivationYes"
                                                                            buttonType="left"
                                                                            textValue="tak"
                                                                            checked={resultsCtx.filtersRequiresActivationYes}
                                                                            onCheckHandler={resultsCtx.onFiltersRequiresActivationYesClick}/>
                                                    <YesNoCheckboxComponent id="requiresActivationNo"
                                                                            buttonType="right"
                                                                            textValue="nie"
                                                                            checked={resultsCtx.filtersRequiresActivationNo}
                                                                            onCheckHandler={resultsCtx.onFiltersRequiresActivationNoClick}/>
                                                </div>
                                                <div className="results_filters-single-filter-content__right-wrapper"></div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="results_filters-single-filter__wrapper">
                                        <div className="results_filters-single-filter-label__wrapper">
                                            <span className="results_label">Wymaga opatrunku wtórnego</span>
                                        </div>
                                        <div className="results_filters-single-filter-content__wrapper">
                                            <div className="results_filters-single-filter-content__inner-wrapper">
                                                <div className="results_filters-single-filter-content__left-wrapper">
                                                    <YesNoCheckboxComponent id="requiresSecondaryDressingYes"
                                                                            buttonType="left"
                                                                            textValue="tak"
                                                                            checked={resultsCtx.filtersRequiresSecondaryDressingYes}
                                                                            onCheckHandler={resultsCtx.onFiltersRequiresSecondaryYesClick}/>
                                                    <YesNoCheckboxComponent id="requiresSecondaryDressingNo"
                                                                            buttonType="right"
                                                                            textValue="nie"
                                                                            checked={resultsCtx.filtersRequiresSecondaryDressingNo}
                                                                            onCheckHandler={resultsCtx.onFiltersRequiresSecondaryDressingNoClick}/>
                                                </div>
                                                <div className="results_filters-single-filter-content__right-wrapper"></div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="results_filters-single-filter__wrapper">
                                        <div className="results_filters-single-filter-label__wrapper">
                                            <span className="results_label">Typ opatrunku</span>
                                        </div>
                                        <div className="results_filters-single-filter-content__wrapper">
                                            <div className="results_filters-single-filter-content__inner-wrapper">
                                                <div className="results_filters-single-filter-content__left-wrapper">
                                                    <YesNoCheckboxComponent id="filtersPrimaryDressing"
                                                                            buttonType="left"
                                                                            textValue="pierwotny"
                                                                            checked={resultsCtx.filtersPrimaryDressing}
                                                                            onCheckHandler={resultsCtx.onFiltersPrimaryDressingClick}/>
                                                    <YesNoCheckboxComponent id="filtersSecondaryDressing"
                                                                            buttonType="right"
                                                                            textValue="wtórny"
                                                                            checked={resultsCtx.filtersSecondaryDressing}
                                                                            onCheckHandler={resultsCtx.onFiltersSecondaryDressingClick}/>
                                                </div>
                                                <div className="results_filters-single-filter-content__right-wrapper"></div>
                                            </div>

                                        </div>
                                    </div>
                                </div>

                                <div className="results_filters-content-right-left__wrapper">

                                    <div className="results_filters-single-filter__wrapper">
                                        <div className="results_filters-single-filter-label__wrapper">
                                            <span className="results_label">Substancje czynne</span>
                                        </div>
                                        <div className="results_filters-single-filter-content__wrapper">
                                            <div className="results_filters-single-filter-content__inner-wrapper">
                                                <div className="results_filters-single-filter-content__left-wrapper">
                                                    <div className="results_filters-select__wrapper">
                                                        <SelectComponent
                                                            value={resultsCtx.filtersSelectedActiveSubstance}
                                                            onValueChange={resultsCtx.onFiltersSelectedActiveSubstanceClick}
                                                            showChooseOption={true}
                                                            data1={resultsCtx.activeSubstancesList}/>
                                                    </div>
                                                </div>
                                                <div className="results_filters-single-filter-content__right-wrapper">
                                                    <ButtonComponent id="filtersSelectedStructureResetButton"
                                                                     className="reset-button"
                                                                     onClick={resultsCtx.onSelectedActiveSubstanceResetButtonClick}
                                                                     value="X"/>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="results_filters-single-filter__wrapper">
                                        <div className="results_filters-single-filter-label__wrapper">
                                            <span className="results_label">Budowa opatrunku</span>
                                        </div>
                                        <div className="results_filters-single-filter-content__wrapper">
                                            <div className="results_filters-single-filter-content__inner-wrapper">
                                                <div className="results_filters-single-filter-content__left-wrapper">
                                                    <div className="results_filters-select__wrapper">
                                                        <SelectComponent value={resultsCtx.filtersSelectedStructure}
                                                                         onValueChange={resultsCtx.onFiltersSelectedStructure}
                                                                         showChooseOption={true}
                                                                         data1={resultsCtx.dressingStructuresList}/>
                                                    </div>
                                                </div>
                                                <div className="results_filters-single-filter-content__right-wrapper">
                                                    <ButtonComponent id="filtersSelectedStructureResetButton"
                                                                     className="reset-button"
                                                                     onClick={resultsCtx.onSelectedStructureResetButtonClick}
                                                                     value="X"/>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="results_filters-single-filter__wrapper">
                                        <div className="results_filters-single-filter-label__wrapper">
                                            <span className="results_label">Częstotliwość zmiany</span>
                                        </div>
                                        <div className="results_filters-single-filter-content__wrapper">
                                            <div className="results_filters-single-filter-content__inner-wrapper">
                                                <div className="results_filters-single-filter-content__left-wrapper">
                                                    <div className="results_filters-select__wrapper">
                                                        <div className="results_select-day-input__wrapper">
                                                            <SelectDayComponent
                                                                value={resultsCtx.filtersSelectedDayFrom}
                                                                type="--od--"
                                                                onValueChange={resultsCtx.onFiltersSelectedDayFromClick}
                                                                options={props.daysFrom}/>
                                                        </div>
                                                        <div className="results_select-day-input__wrapper">
                                                            <SelectDayComponent value={resultsCtx.filtersSelectedDayTo}
                                                                                type="--do--"
                                                                                onValueChange={resultsCtx.onFiltersSelectedDayToClick}
                                                                                options={props.daysTo}/>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="results_filters-single-filter-content__right-wrapper">
                                                    <ButtonComponent id="filtersSelectedStructureResetButton"
                                                                     className="reset-button"
                                                                     onClick={resultsCtx.onSelectedDaysResetButtonClick}
                                                                     value="X"/>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                }

                {resultsCtx.dressingsFiltered.length !== 0 &&
                    <div className={"results_number-of-dressings__wrapper"}>
                        <NumberOfDressingsComponent dressings={resultsCtx.dressingsAdditionallyFiltered}/>
                    </div>
                }

                {resultsCtx.dressingsFiltered.length !== 0 &&
                    <div className="results_search-bar__wrapper">

                        <div className="results_search-bar__left-wrapper">
                            <div className="results_search-bar__inner-wrapper">
                                <InputComponent value={resultsCtx.searchedText} inputHandler={inputContentHandler}/>
                            </div>
                        </div>

                        {resultsCtx.dressingsAdditionallyFiltered.length > 1 &&
                            <div className="results_search-bar__right-wrapper">
                                <ButtonComponent id="compareDressingsButton"
                                                 value="porównaj opatrunki >>"
                                                 type="button"
                                                 onClick={compareButtonHandler}
                                                 disabled={resultsCtx.compareDressingsButtonDisabled}
                                                 className='compare-dressings-button'/>
                            </div>
                        }
                    </div>
                }

                {resultsCtx.dressingsFiltered.length !== 0 &&
                    <div className="results_content__wrapper">

                        {resultsCtx.dressingsAdditionallyFiltered.length === 0 &&
                            <div>
                                <p className="results_broaden-search-criteria">Nie znaleziono wyników.</p>
                                <p className="results_broaden-search-criteria">Spróbuj rozszerzyć kryteria
                                    wyszukiwania.</p>
                            </div>
                        }

                        <ResultsAllTableComponent/>
                    </div>
                }

                {resultsCtx.dressingsFiltered.length !== 0 &&
                    <div className="results_branding__wrapper">
                        <BrandingComponent/>
                    </div>
                }

                {resultsCtx.dressingsFiltered.length !== 0 &&
                    <div className="results_copyright__wrapper">
                        <PopoverComponent/>
                    </div>
                }

            </div>
        </div>
    );
}

export default Results;

import React, {useContext} from 'react';
import './resultsdoubletable.css';
import {ResultsContext} from '../../../../store/results-context';
import {DressingModel} from '../../model/dressing.model';
import WordsVerticalComponent from '../words-vertical/WordsVertical.component';
import {DressingPricingModel} from '../../model/dressing-pricing.model';
import {notNull} from '../../utils/null-check';
import {ShowAdditionalColumn} from '../../utils/show-additional-column';

const ResultsDoubleTableComponent: React.FC = () => {

    const resultsCtx = useContext(ResultsContext);

    function getDressingPrices(dressingId: string): DressingPricingModel {
        return resultsCtx.dressingPricingDataFull.filter((pricing: DressingPricingModel) => pricing.id === dressingId)[0];
    }

    return (
        <table id="doubleResultTable">
            <tbody>
            <tr>
                {ShowAdditionalColumn() && <td className="additional-table-column border-top">Nazwa</td>}
                {resultsCtx.checkedDressingsList.map((dressing: DressingModel) =>
                    <td width="50%" key={dressing.id} className="results-double-table_border-top">
                        <p className="results-double-table_dressing-name__text">
                            {dressing.name}
                        </p>
                        <p className="results-double-table_dressing-producer__text">
                            {dressing.producer}
                        </p>
                    </td>
                )}
            </tr>
            <tr>
                {ShowAdditionalColumn() && <td className="additional-table-column">Opis</td>}
                {resultsCtx.checkedDressingsList.map((dressing: DressingModel) =>
                    <td key={dressing.id}>
                        {dressing.structureFull}
                    </td>
                )}
            </tr>
            <tr>
                {ShowAdditionalColumn() && <td className="additional-table-column">Działanie</td>}
                {resultsCtx.checkedDressingsList.map((dressing: DressingModel) =>
                    <td key={dressing.id}>
                        <div className="results-double-table_dressing-action__wrapper">
                            {!ShowAdditionalColumn() && <p className="results-double-table__section-header">Działanie</p>}
                            <div className={!ShowAdditionalColumn() ? "results-double-table__section-content" : ""}>
                                {dressing.action}
                            </div>
                        </div>
                    </td>)
                }
            </tr>
            <tr>
                {ShowAdditionalColumn() && <td className="additional-table-column">Jakie rany?</td>}
                {resultsCtx.checkedDressingsList.map((dressing: DressingModel) =>
                    <td key={dressing.id}>
                        {!ShowAdditionalColumn() && <p className="results-double-table__section-header">Jakie rany?</p>}
                        <div className={!ShowAdditionalColumn() ? "results-double-table__section-content" : ""}>
                            <WordsVerticalComponent data={dressing.typeOfWound}/>
                        </div>
                    </td>
                )}
            </tr>
            <tr>
                {ShowAdditionalColumn() && <td className="additional-table-column">Kiedy zmieniać?</td>}
                {resultsCtx.checkedDressingsList.map((dressing: DressingModel) =>
                    <td key={dressing.id}>
                        {!ShowAdditionalColumn() && <p className="results-double-table__section-header">Kiedy zmieniać?</p>}
                        <div className={!ShowAdditionalColumn() ? "results-double-table__section-content" : ""}>
                            {dressing.whenToChangeDescription}
                        </div>
                    </td>
                )}
            </tr>

            <tr>
                {ShowAdditionalColumn() && <td className="additional-table-column">Substancje czynne</td>}
                {resultsCtx.checkedDressingsList.map((dressing: DressingModel) =>
                    <td key={dressing.id}>
                        {!ShowAdditionalColumn() && <p className="results-double-table__section-header">Substancje czynne</p>}
                        <div className={!ShowAdditionalColumn() ? "results-double-table__section-content" : ""}>
                            <WordsVerticalComponent data={dressing.activeSubstances}></WordsVerticalComponent>
                        </div>
                    </td>
                )}
            </tr>
            <tr>
                {ShowAdditionalColumn() && <td className="additional-table-column">Wymaga opatrunku wtórnego</td>}
                {resultsCtx.checkedDressingsList.map((dressing: DressingModel) =>
                    <td key={dressing.id}>
                        {!ShowAdditionalColumn() && <p className="results-double-table__section-header">Wymaga opatrunku wtórnego</p>}
                        <div className={!ShowAdditionalColumn() ? "results-double-table__section-content" : ""}>
                            {dressing.requiresSecondaryDressing ? 'wymaga opatrunku wtórnego' : 'nie wymaga opatrunku wtórnego'}
                        </div>
                    </td>)
                }
            </tr>
            <tr>
                {ShowAdditionalColumn() && <td className="additional-table-column">Wymaga aktywacji</td>}
                {resultsCtx.checkedDressingsList.map((dressing: DressingModel) =>
                    <td key={dressing.id}>
                        {!ShowAdditionalColumn() && <p className="results-double-table__section-header">Wymaga aktywacji</p>}
                        <div className={!ShowAdditionalColumn() ? "results-double-table__section-content" : ""}>
                            {dressing.requiresActivation ? 'wymaga aktywacji' : 'nie wymaga aktywacji'}
                        </div>
                    </td>)
                }
            </tr>
            <tr>
                {ShowAdditionalColumn() && <td className="additional-table-column">Dodatkowe wymagania</td>}
                {resultsCtx.checkedDressingsList.map((dressing: DressingModel) =>
                    <td key={dressing.id}>
                        {!ShowAdditionalColumn() && <p className="results-double-table__section-header">Dodatkowe wymagania</p>}
                        <div className={!ShowAdditionalColumn() ? "results-double-table__section-content" : ""}>
                            {dressing.additionalRequirements}
                        </div>
                    </td>)
                }
            </tr>
            <tr>
                {ShowAdditionalColumn() && <td className="additional-table-column">Ważne cechy</td>}
                {resultsCtx.checkedDressingsList.map((dressing: DressingModel) =>
                    <td key={dressing.id}>
                        {!ShowAdditionalColumn() && <p className="results-double-table__section-header">Ważne cechy</p>}
                        <div className={!ShowAdditionalColumn() ? "results-double-table__section-content" : ""}>
                            <WordsVerticalComponent data={dressing.tags}/>
                        </div>
                    </td>)
                }
            </tr>

            <tr>
                {ShowAdditionalColumn() && <td className="additional-table-column">Rozmiary i ceny</td>}
                {resultsCtx.checkedDressingsList.map((dressing: DressingModel) =>
                    <td key={dressing.id}>
                        {!ShowAdditionalColumn() && <p className="results-double-table__section-header">Rozmiary i ceny</p>}
                        <div className={!ShowAdditionalColumn() ? "results-double-table__prices-content" : "font-size-12"}>

                            {getDressingPrices(dressing.id).size1 &&
                                <div>
                                    {getDressingPrices(dressing.id).size1_100 &&
                                        <div className="results-double-table__price-text">
                                            <div className="results-double-table__section-header">
                                                {getDressingPrices(dressing.id).size1}
                                            </div>
                                            <div className="results-double-table_hide-on-small-screen">
                                                &nbsp;:&nbsp;
                                            </div>
                                            <div>
                                                {getDressingPrices(dressing.id).size1_100} zł (100%)
                                            </div>
                                            <div className="results-double-table_hide-on-small-screen">
                                                &nbsp;-&nbsp;
                                            </div>
                                            <div>
                                                {getDressingPrices(dressing.id).size1_30} zł (30%)
                                            </div>
                                            <div>
                                                {notNull(getDressingPrices(dressing.id).size1_B) &&
                                                    <div className="results-double-table_B">
                                                        <div className="results-double-table_hide-on-small-screen">
                                                            &nbsp;-&nbsp;
                                                        </div>
                                                        <span>{getDressingPrices(dressing.id).size1_B} zł (B)</span>
                                                    </div>
                                                }
                                            </div>
                                        </div>
                                    }
                                    {getDressingPrices(dressing.id).size1_suggested &&
                                        <div className="results-double-table__price-text">
                                            <div className="results-double-table__section-header">
                                                {getDressingPrices(dressing.id).size1}
                                            </div>
                                            <div className="results-double-table_hide-on-small-screen">
                                                &nbsp;:&nbsp;
                                            </div>
                                            <div>
                                                {getDressingPrices(dressing.id).size1_suggested} (cena sugerowana)
                                            </div>
                                        </div>
                                    }
                                </div>
                            }

                            {getDressingPrices(dressing.id).size2 &&
                                <div>
                                    {getDressingPrices(dressing.id).size2_100 &&
                                        <div className="results-double-table__price-text">
                                            <div className="results-double-table__section-header">
                                                {getDressingPrices(dressing.id).size2}
                                            </div>
                                            <div className="results-double-table_hide-on-small-screen">
                                                &nbsp;:&nbsp;
                                            </div>
                                            <div>
                                                {getDressingPrices(dressing.id).size2_100} zł (100%)
                                            </div>
                                            <div className="results-double-table_hide-on-small-screen">
                                                &nbsp;-&nbsp;
                                            </div>
                                            <div>
                                                {getDressingPrices(dressing.id).size2_30} zł (30%)
                                            </div>
                                            <div>
                                                {notNull(getDressingPrices(dressing.id).size2_B) &&
                                                    <div className="results-double-table_B">
                                                        <div className="results-double-table_hide-on-small-screen">
                                                            &nbsp;-&nbsp;
                                                        </div>
                                                        <span>{getDressingPrices(dressing.id).size2_B} zł (B)</span>
                                                    </div>
                                                }
                                            </div>
                                        </div>
                                    }
                                    {getDressingPrices(dressing.id).size2_suggested &&
                                        <div className="results-double-table__price-text">
                                            <div className="results-double-table__section-header">
                                                {getDressingPrices(dressing.id).size2}
                                            </div>
                                            <div className="results-double-table_hide-on-small-screen">
                                                &nbsp;:&nbsp;
                                            </div>
                                            <div>
                                                {getDressingPrices(dressing.id).size2_suggested} (cena sugerowana)
                                            </div>
                                        </div>
                                    }
                                </div>
                            }

                            {getDressingPrices(dressing.id).size3 &&
                                <div>
                                    {getDressingPrices(dressing.id).size3_100 &&
                                        <div className="results-double-table__price-text">
                                            <div className="results-double-table__section-header">
                                                {getDressingPrices(dressing.id).size3}
                                            </div>
                                            <div className="results-double-table_hide-on-small-screen">
                                                &nbsp;:&nbsp;
                                            </div>
                                            <div>
                                                {getDressingPrices(dressing.id).size3_100} zł (100%)
                                            </div>
                                            <div className="results-double-table_hide-on-small-screen">
                                                &nbsp;-&nbsp;
                                            </div>
                                            <div>
                                                {getDressingPrices(dressing.id).size3_30} zł (30%)
                                            </div>
                                            <div>
                                                {notNull(getDressingPrices(dressing.id).size3_B) &&
                                                    <div className="results-double-table_B">
                                                        <div className="results-double-table_hide-on-small-screen">
                                                            &nbsp;-&nbsp;
                                                        </div>
                                                        <span>{getDressingPrices(dressing.id).size3_B} zł (B)</span>
                                                    </div>
                                                }
                                            </div>
                                        </div>
                                    }
                                    {getDressingPrices(dressing.id).size3_suggested &&
                                        <div className="results-double-table__price-text">
                                            <div className="results-double-table__section-header">
                                                {getDressingPrices(dressing.id).size3}
                                            </div>
                                            <div className="results-double-table_hide-on-small-screen">
                                                &nbsp;:&nbsp;
                                            </div>
                                            <div>
                                                {getDressingPrices(dressing.id).size3_suggested} (cena sugerowana)
                                            </div>
                                        </div>
                                    }
                                </div>
                            }

                            {getDressingPrices(dressing.id).size4 &&
                                <div>
                                    {getDressingPrices(dressing.id).size4_100 &&
                                        <div className="results-double-table__price-text">
                                            <div className="results-double-table__section-header">
                                                {getDressingPrices(dressing.id).size4}
                                            </div>
                                            <div className="results-double-table_hide-on-small-screen">
                                                &nbsp;:&nbsp;
                                            </div>
                                            <div>
                                                {getDressingPrices(dressing.id).size4_100} zł (100%)
                                            </div>
                                            <div className="results-double-table_hide-on-small-screen">
                                                &nbsp;-&nbsp;
                                            </div>
                                            <div>
                                                {getDressingPrices(dressing.id).size4_30} zł (30%)
                                            </div>
                                            <div>
                                                {notNull(getDressingPrices(dressing.id).size4_B) &&
                                                    <div className="results-double-table_B">
                                                        <div className="results-double-table_hide-on-small-screen">
                                                            &nbsp;-&nbsp;
                                                        </div>
                                                        <span>{getDressingPrices(dressing.id).size4_B} zł (B)</span>
                                                    </div>
                                                }
                                            </div>
                                        </div>
                                    }
                                    {getDressingPrices(dressing.id).size4_suggested &&
                                        <div className="results-double-table__price-text">
                                            <div className="results-double-table__section-header">
                                                {getDressingPrices(dressing.id).size4}
                                            </div>
                                            <div className="results-double-table_hide-on-small-screen">
                                                &nbsp;:&nbsp;
                                            </div>
                                            <div>
                                                {getDressingPrices(dressing.id).size4_suggested} (cena sugerowana)
                                            </div>
                                        </div>
                                    }
                                </div>
                            }

                            {getDressingPrices(dressing.id).size5 &&
                                <div>
                                    {getDressingPrices(dressing.id).size5_100 &&
                                        <div className="results-double-table__price-text">
                                            <div className="results-double-table__section-header">
                                                {getDressingPrices(dressing.id).size5}
                                            </div>
                                            <div className="results-double-table_hide-on-small-screen">
                                                &nbsp;:&nbsp;
                                            </div>
                                            <div>
                                                {getDressingPrices(dressing.id).size5_100} zł (100%)
                                            </div>
                                            <div className="results-double-table_hide-on-small-screen">
                                                &nbsp;-&nbsp;
                                            </div>
                                            <div>
                                                {getDressingPrices(dressing.id).size5_30} zł (30%)
                                            </div>
                                            <div>
                                                {notNull(getDressingPrices(dressing.id).size5_B) &&
                                                    <div className="results-double-table_B">
                                                        <div className="results-double-table_hide-on-small-screen">
                                                            &nbsp;-&nbsp;
                                                        </div>
                                                        <span>{getDressingPrices(dressing.id).size5_B} zł (B)</span>
                                                    </div>
                                                }
                                            </div>
                                        </div>
                                    }
                                    {getDressingPrices(dressing.id).size5_suggested &&
                                        <div className="results-double-table__price-text">
                                            <div className="results-double-table__section-header">
                                                {getDressingPrices(dressing.id).size5}
                                            </div>
                                            <div className="results-double-table_hide-on-small-screen">
                                                &nbsp;:&nbsp;
                                            </div>
                                            <div>
                                                {getDressingPrices(dressing.id).size5_suggested} (cena sugerowana)
                                            </div>
                                        </div>
                                    }
                                </div>
                            }

                            {getDressingPrices(dressing.id).size6 &&
                                <div>
                                    {getDressingPrices(dressing.id).size6_100 &&
                                        <div className="results-double-table__price-text">
                                            <div className="results-double-table__section-header">
                                                {getDressingPrices(dressing.id).size6}
                                            </div>
                                            <div className="results-double-table_hide-on-small-screen">
                                                &nbsp;:&nbsp;
                                            </div>
                                            <div>
                                                {getDressingPrices(dressing.id).size6_100} zł (100%)
                                            </div>
                                            <div className="results-double-table_hide-on-small-screen">
                                                &nbsp;-&nbsp;
                                            </div>
                                            <div>
                                                {getDressingPrices(dressing.id).size6_30} zł (30%)
                                            </div>
                                            <div>
                                                {notNull(getDressingPrices(dressing.id).size6_B) &&
                                                    <div className="results-double-table_B">
                                                        <div className="results-double-table_hide-on-small-screen">
                                                            &nbsp;-&nbsp;
                                                        </div>
                                                        <span>{getDressingPrices(dressing.id).size6_B} zł (B)</span>
                                                    </div>
                                                }
                                            </div>
                                        </div>
                                    }
                                    {getDressingPrices(dressing.id).size6_suggested &&
                                        <div className="results-double-table__price-text">
                                            <div className="results-double-table__section-header">
                                                {getDressingPrices(dressing.id).size6}
                                            </div>
                                            <div className="results-double-table_hide-on-small-screen">
                                                &nbsp;:&nbsp;
                                            </div>
                                            <div>
                                                {getDressingPrices(dressing.id).size6_suggested} (cena sugerowana)
                                            </div>
                                        </div>
                                    }
                                </div>
                            }

                            {getDressingPrices(dressing.id).size7 &&
                                <div>
                                    {getDressingPrices(dressing.id).size7_100 &&
                                        <div className="results-double-table__price-text">
                                            <div className="results-double-table__section-header">
                                                {getDressingPrices(dressing.id).size7}
                                            </div>
                                            <div className="results-double-table_hide-on-small-screen">
                                                &nbsp;:&nbsp;
                                            </div>
                                            <div>
                                                {getDressingPrices(dressing.id).size7_100} zł (100%)
                                            </div>
                                            <div className="results-double-table_hide-on-small-screen">
                                                &nbsp;-&nbsp;
                                            </div>
                                            <div>
                                                {getDressingPrices(dressing.id).size7_30} zł (30%)
                                            </div>
                                            <div>
                                                {notNull(getDressingPrices(dressing.id).size7_B) &&
                                                    <div className="results-double-table_B">
                                                        <div className="results-double-table_hide-on-small-screen">
                                                            &nbsp;-&nbsp;
                                                        </div>
                                                        <span>{getDressingPrices(dressing.id).size7_B} zł (B)</span>
                                                    </div>
                                                }
                                            </div>
                                        </div>
                                    }
                                    {getDressingPrices(dressing.id).size7_suggested &&
                                        <div className="results-double-table__price-text">
                                            <div className="results-double-table__section-header">
                                                {getDressingPrices(dressing.id).size7}
                                            </div>
                                            <div className="results-double-table_hide-on-small-screen">
                                                &nbsp;:&nbsp;
                                            </div>
                                            <div>
                                                {getDressingPrices(dressing.id).size7_suggested} (cena sugerowana)
                                            </div>
                                        </div>
                                    }
                                </div>
                            }

                            {getDressingPrices(dressing.id).size8 &&
                                <div>
                                    {getDressingPrices(dressing.id).size8_100 &&
                                        <div className="results-double-table__price-text">
                                            <div className="results-double-table__section-header">
                                                {getDressingPrices(dressing.id).size8}
                                            </div>
                                            <div className="results-double-table_hide-on-small-screen">
                                                &nbsp;:&nbsp;
                                            </div>
                                            <div>
                                                {getDressingPrices(dressing.id).size8_100} zł (100%)
                                            </div>
                                            <div className="results-double-table_hide-on-small-screen">
                                                &nbsp;-&nbsp;
                                            </div>
                                            <div>
                                                {getDressingPrices(dressing.id).size8_30} zł (30%)
                                            </div>
                                            <div>
                                                {notNull(getDressingPrices(dressing.id).size8_B) &&
                                                    <div className="results-double-table_B">
                                                        <div className="results-double-table_hide-on-small-screen">
                                                            &nbsp;-&nbsp;
                                                        </div>
                                                        <span>{getDressingPrices(dressing.id).size8_B} zł (B)</span>
                                                    </div>
                                                }
                                            </div>
                                        </div>
                                    }
                                    {getDressingPrices(dressing.id).size8_suggested &&
                                        <div className="results-double-table__price-text">
                                            <div className="results-double-table__section-header">
                                                {getDressingPrices(dressing.id).size8}
                                            </div>
                                            <div className="results-double-table_hide-on-small-screen">
                                                &nbsp;:&nbsp;
                                            </div>
                                            <div>
                                                {getDressingPrices(dressing.id).size8_suggested} (cena sugerowana)
                                            </div>
                                        </div>
                                    }
                                </div>
                            }

                            {getDressingPrices(dressing.id).size9 &&
                                <div>
                                    {getDressingPrices(dressing.id).size9_100 &&
                                        <div className="results-double-table__price-text">
                                            <div className="results-double-table__section-header">
                                                {getDressingPrices(dressing.id).size9}
                                            </div>
                                            <div className="results-double-table_hide-on-small-screen">
                                                &nbsp;:&nbsp;
                                            </div>
                                            <div>
                                                {getDressingPrices(dressing.id).size9_100} zł (100%)
                                            </div>
                                            <div className="results-double-table_hide-on-small-screen">
                                                &nbsp;-&nbsp;
                                            </div>
                                            <div>
                                                {getDressingPrices(dressing.id).size9_30} zł (30%)
                                            </div>
                                            <div>
                                                {notNull(getDressingPrices(dressing.id).size9_B) &&
                                                    <div className="results-double-table_B">
                                                        <div className="results-double-table_hide-on-small-screen">
                                                            &nbsp;-&nbsp;
                                                        </div>
                                                        <span>{getDressingPrices(dressing.id).size9_B} zł (B)</span>
                                                    </div>
                                                }
                                            </div>
                                        </div>
                                    }
                                    {getDressingPrices(dressing.id).size9_suggested &&
                                        <div className="results-double-table__price-text">
                                            <div className="results-double-table__section-header">
                                                {getDressingPrices(dressing.id).size9}
                                            </div>
                                            <div className="results-double-table_hide-on-small-screen">
                                                &nbsp;:&nbsp;
                                            </div>
                                            <div>
                                                {getDressingPrices(dressing.id).size9_suggested} (cena sugerowana)
                                            </div>
                                        </div>
                                    }
                                </div>
                            }

                            {getDressingPrices(dressing.id).size10 &&
                                <div>
                                    {getDressingPrices(dressing.id).size10_100 &&
                                        <div className="results-double-table__price-text">
                                            <div className="results-double-table__section-header">
                                                {getDressingPrices(dressing.id).size10}
                                            </div>
                                            <div className="results-double-table_hide-on-small-screen">
                                                &nbsp;:&nbsp;
                                            </div>
                                            <div>
                                                {getDressingPrices(dressing.id).size10_100} zł (100%)
                                            </div>
                                            <div className="results-double-table_hide-on-small-screen">
                                                &nbsp;-&nbsp;
                                            </div>
                                            <div>
                                                {getDressingPrices(dressing.id).size10_30} zł (30%)
                                            </div>
                                            <div>
                                                {notNull(getDressingPrices(dressing.id).size10_B) &&
                                                    <div className="results-double-table_B">
                                                        <div className="results-double-table_hide-on-small-screen">
                                                            &nbsp;-&nbsp;
                                                        </div>
                                                        <span>{getDressingPrices(dressing.id).size10_B} zł (B)</span>
                                                    </div>
                                                }
                                            </div>
                                        </div>
                                    }
                                    {getDressingPrices(dressing.id).size10_suggested &&
                                        <div className="results-double-table__price-text">
                                            <div className="results-double-table__section-header">
                                                {getDressingPrices(dressing.id).size10}
                                            </div>
                                            <div className="results-double-table_hide-on-small-screen">
                                                &nbsp;:&nbsp;
                                            </div>
                                            <div>
                                                {getDressingPrices(dressing.id).size10_suggested} (cena sugerowana)
                                            </div>
                                        </div>
                                    }
                                </div>
                            }

                            {getDressingPrices(dressing.id).size11 &&
                                <div>
                                    {getDressingPrices(dressing.id).size11_100 &&
                                        <div className="results-double-table__price-text">
                                            <div className="results-double-table__section-header">
                                                {getDressingPrices(dressing.id).size11}
                                            </div>
                                            <div className="results-double-table_hide-on-small-screen">
                                                &nbsp;:&nbsp;
                                            </div>
                                            <div>
                                                {getDressingPrices(dressing.id).size11_100} zł (100%)
                                            </div>
                                            <div className="results-double-table_hide-on-small-screen">
                                                &nbsp;-&nbsp;
                                            </div>
                                            <div>
                                                {getDressingPrices(dressing.id).size11_30} zł (30%)
                                            </div>
                                            <div>
                                                {notNull(getDressingPrices(dressing.id).size11_B) &&
                                                    <div className="results-double-table_B">
                                                        <div className="results-double-table_hide-on-small-screen">
                                                            &nbsp;-&nbsp;
                                                        </div>
                                                        <span>{getDressingPrices(dressing.id).size11_B} zł (B)</span>
                                                    </div>
                                                }
                                            </div>
                                        </div>
                                    }
                                    {getDressingPrices(dressing.id).size11_suggested &&
                                        <div className="results-double-table__price-text">
                                            <div className="results-double-table__section-header">
                                                {getDressingPrices(dressing.id).size11}
                                            </div>
                                            <div className="results-double-table_hide-on-small-screen">
                                                &nbsp;:&nbsp;
                                            </div>
                                            <div>
                                                {getDressingPrices(dressing.id).size11_suggested} (cena sugerowana)
                                            </div>
                                        </div>
                                    }
                                </div>
                            }

                            {getDressingPrices(dressing.id).size12 &&
                                <div>
                                    {getDressingPrices(dressing.id).size12_100 &&
                                        <div className="results-double-table__price-text">
                                            <div className="results-double-table__section-header">
                                                {getDressingPrices(dressing.id).size12}
                                            </div>
                                            <div className="results-double-table_hide-on-small-screen">
                                                &nbsp;:&nbsp;
                                            </div>
                                            <div>
                                                {getDressingPrices(dressing.id).size12_100} zł (100%)
                                            </div>
                                            <div className="results-double-table_hide-on-small-screen">
                                                &nbsp;-&nbsp;
                                            </div>
                                            <div>
                                                {getDressingPrices(dressing.id).size12_30} zł (30%)
                                            </div>
                                            <div>
                                                {notNull(getDressingPrices(dressing.id).size12_B) &&
                                                    <div className="results-double-table_B">
                                                        <div className="results-double-table_hide-on-small-screen">
                                                            &nbsp;-&nbsp;
                                                        </div>
                                                        <span>{getDressingPrices(dressing.id).size12_B} zł (B)</span>
                                                    </div>
                                                }
                                            </div>
                                        </div>
                                    }
                                    {getDressingPrices(dressing.id).size12_suggested &&
                                        <div className="results-double-table__price-text">
                                            <div className="results-double-table__section-header">
                                                {getDressingPrices(dressing.id).size12}
                                            </div>
                                            <div className="results-double-table_hide-on-small-screen">
                                                &nbsp;:&nbsp;
                                            </div>
                                            <div>
                                                {getDressingPrices(dressing.id).size12_suggested} (cena sugerowana)
                                            </div>
                                        </div>
                                    }
                                </div>
                            }
                        </div>
                    </td>)
                }
            </tr>
            </tbody>
        </table>
    );
}
export default ResultsDoubleTableComponent;

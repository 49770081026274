import React from 'react';
import './tags.css';

type TagsProps = {
    tags: string[];
};

const TagsComponent: React.FC<TagsProps> = (props: TagsProps) => {
    return (
        <div className="tags_main-wrapper">
            {props.tags.map(tag => (<div key={tag} className="tags_single-tag">{tag}</div>))}
        </div>
    );
}

export default TagsComponent;

import React from 'react';
import * as SelectPrimitive from '@radix-ui/react-select';
import {CheckIcon, ChevronDownIcon, ChevronUpIcon} from '@radix-ui/react-icons';
import SelectButton from '../selectbutton/SelectButton.component';
import './select.css';

type SelectProps = {
    value: string;
    showChooseOption: boolean;
    onValueChange: (value: any) => void;
    data1: string[];
    data1Header?: string;
    data2?: string[];
    data2Header?: string;
    data3?: string[];
    data3Header?: string;
};

const SelectComponent = (props: SelectProps) => {
    return (
        <SelectPrimitive.Root value={props.value !== 'no-option' ? props.value : 'no-option'} onValueChange={props.onValueChange}>
            <SelectPrimitive.Trigger className="select-trigger" asChild>
                <SelectButton>
                    <SelectPrimitive.Value/>
                </SelectButton>
            </SelectPrimitive.Trigger>
            <SelectPrimitive.Content className="select-content">
                <SelectPrimitive.ScrollUpButton className="select-scroll-button">
                    <ChevronUpIcon />
                </SelectPrimitive.ScrollUpButton>
                <SelectPrimitive.Viewport className="select-viewport">

                    {props.showChooseOption &&
                        <SelectPrimitive.Group>
                            <SelectPrimitive.Item
                                key="no-option"
                                value="no-option"
                                disabled={true}
                                className="default-option">
                                <SelectPrimitive.ItemText>-- wybierz --</SelectPrimitive.ItemText>
                            </SelectPrimitive.Item>
                        </SelectPrimitive.Group>
                    }

                    {props.showChooseOption &&
                        <SelectPrimitive.Separator className="select-separator" />
                    }

                    <SelectPrimitive.Group>
                        {props.data1Header && <SelectPrimitive.Label className="select-label">{props.data1Header}</SelectPrimitive.Label>}

                        {props.data1.map(
                            (f, i) => (
                                <SelectPrimitive.Item
                                    key={`${f}-${i}`}
                                    value={f}
                                    disabled={f === "1 dzień"}
                                    className="select-item">
                                    <SelectPrimitive.ItemText>{f}</SelectPrimitive.ItemText>
                                    <SelectPrimitive.ItemIndicator className="select-item-indicator">
                                        <CheckIcon />
                                    </SelectPrimitive.ItemIndicator>
                                </SelectPrimitive.Item>
                            )
                        )}
                    </SelectPrimitive.Group>

                    {props.data2 && <SelectPrimitive.Separator className="select-separator" />}

                    {props.data2 &&
                        <SelectPrimitive.Group>
                            {props.data2Header && <SelectPrimitive.Label className="select-label">{props.data2Header}</SelectPrimitive.Label>}
                            {props.data2.map(
                                (f, i) => (
                                    <SelectPrimitive.Item
                                        key={`${f}-${i}`}
                                        value={f.toLowerCase()}
                                        disabled={f === "1 dzień"}
                                        className="select-item">
                                        <SelectPrimitive.ItemText>{f}</SelectPrimitive.ItemText>
                                        <SelectPrimitive.ItemIndicator className="select-item-indicator">
                                            <CheckIcon />
                                        </SelectPrimitive.ItemIndicator>
                                    </SelectPrimitive.Item>
                                )
                            )}
                        </SelectPrimitive.Group>
                    }

                    {props.data3 && <SelectPrimitive.Separator className="select-separator" />}

                    {props.data3 &&
                        <SelectPrimitive.Group>
                            {props.data3Header && <SelectPrimitive.Label className="select-label">{props.data3Header}</SelectPrimitive.Label>}
                            {props.data3.map(
                                (f, i) => (
                                    <SelectPrimitive.Item
                                        key={`${f}-${i}`}
                                        value={f.toLowerCase()}
                                        disabled={f === "1 dzień"}
                                        className="select-item">
                                        <SelectPrimitive.ItemText>{f}</SelectPrimitive.ItemText>
                                        <SelectPrimitive.ItemIndicator className="select-item-indicator">
                                            <CheckIcon />
                                        </SelectPrimitive.ItemIndicator>
                                    </SelectPrimitive.Item>
                                )
                            )}
                        </SelectPrimitive.Group>
                    }
                </SelectPrimitive.Viewport>
                <SelectPrimitive.ScrollDownButton className="select-scroll-button">
                    <ChevronDownIcon />
                </SelectPrimitive.ScrollDownButton>
            </SelectPrimitive.Content>
        </SelectPrimitive.Root>
    );
};

export { SelectComponent };

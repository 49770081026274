import * as _ from 'underscore';

export function whenToChangeIsWithinSelectedRange(whenToChange: number[], dayFrom: string, dayTo: string): boolean {

    if (dayFrom === 'no-option' && dayTo === 'no-option') {
        return true;
    } else if (dayFrom !== 'no-option' && dayTo !== 'no-option') {
        return _.contains(whenToChange, Number.parseInt(dayFrom)) && _.contains(whenToChange, Number.parseInt(dayTo));
    }
    return _.contains(whenToChange, (dayFrom === 'no-option' ? 0 : Number.parseInt(dayFrom))) ||
        _.contains(whenToChange, (dayTo === 'no-option' ? 0 : Number.parseInt(dayTo)));
}

import React from 'react';
import {ResultsModel} from '../components/shared/model/results.model';
import {getApplicationType} from '../components/shared/utils/get-application-type';
import {DressingModel} from '../components/shared/model/dressing.model';

export const ResultsContext = React.createContext<ResultsModel>({
    userId: '',
    profession: '',
    onProfessionChange: (value: string) => {},
    licenseNumber: '',
    onLicenseNumberChange: (value: string) => {},
    agreeToTerms: false,
    onAgreeToTermsChange: (state: boolean) => {},
    applicationType: getApplicationType(),
    infectedWound: false,
    onInfectedWoundClick: (state: boolean) => {},
    burnedWound: false,
    onBurnedWoundClick: (state: boolean) => {},
    especiallyDryWound: false,
    onEspeciallyDryWoundClick: (state: boolean) => {},
    woundWithNecrosis: false,
    onWoundWithNecrosisClick: (state: boolean) => {},
    maintainingMoistureEnvironment: false,
    onMaintainingMoistureEnvironmentClick: (state: boolean) => {},
    adhesionToWound: false,
    onAdhesionToWoundClick: (state: boolean) => {},
    combatingBiofilm: false,
    onCombatingBiofilmClick: (state: boolean) => {},
    odorAbsorption: false,
    onOdorAbsorptionClick: (state: boolean) => {},
    canBeTrimmed: false,
    onCanBeTrimmedClick: (state: boolean) => {},
    filtersRequiresActivationYes: false,
    onFiltersRequiresActivationYesClick: (state: boolean) => {},
    filtersRequiresActivationNo: false,
    onFiltersRequiresActivationNoClick: (state: boolean) => {},
    filtersRequiresSecondaryDressingYes: false,
    onFiltersRequiresSecondaryYesClick: (state: boolean) => {},
    filtersRequiresSecondaryDressingNo: false,
    onFiltersRequiresSecondaryDressingNoClick: (state: boolean) => {},
    filtersPrimaryDressing: false,
    onFiltersPrimaryDressingClick: (state: boolean) => {},
    filtersSecondaryDressing: false,
    onFiltersSecondaryDressingClick: (state: boolean) => {},
    filtersSelectedActiveSubstance: 'no-option',
    onFiltersSelectedActiveSubstanceClick: (activeSubstance: string) => {},
    onSelectedActiveSubstanceResetButtonClick: () => {},
    filtersSelectedStructure: 'no-option',
    onFiltersSelectedStructure: (structure: string) => {},
    onSelectedStructureResetButtonClick: () => {},
    filtersSelectedDayFrom: 'no-option',
    onFiltersSelectedDayFromClick: (dayFrom: string) => {},
    filtersSelectedDayTo: 'no-option',
    onFiltersSelectedDayToClick: (dayTo: string) => {},
    onSelectedDaysResetButtonClick: () => {},
    activeFilters: false,
    setActiveFilters: (state: boolean) => {},
    compareDressingsButtonDisabled: true,
    setCompareDressingsButtonDisabled: (state: boolean) => {},
    dressingsFull: [],
    dressingPricingDataFull: [],
    dressingsFiltered: [],
    searchedText: '',
    onSearchedTextChange: (searchedText: string) => {},
    dressingsAdditionallyFiltered: [],
    setDressingsAdditionallyFiltered: (dressings: DressingModel[]) => {},
    activeSubstancesList: [],
    dressingStructuresList: [],
    singleResultDressing: null as any,
    checkedDressingsList: [],
    setCheckedDressingsList: (dressings: DressingModel[]) => {},
    checkedDressingsCounter: 0,
    setCheckedDressingsCounter: (checked: number) => {},
    sortedAlphabetically: true,
    setSortedAlphabetically: (state: boolean) => {}
});

export function saveChosenFiltersToDatabase(resultsCtx: any): void {
    fetch('https://medutoolsbe.herokuapp.com/api/v1/dressings/filters', {
        method: 'POST',
        body: JSON.stringify({
            userId: resultsCtx.userId,
            infectedWound: resultsCtx.infectedWound,
            burnedWound: resultsCtx.burnedWound,
            especiallyDryWound: resultsCtx.especiallyDryWound,
            woundWithNecrosis: resultsCtx.woundWithNecrosis,
            maintainingMoistureEnvironment: resultsCtx.maintainingMoistureEnvironment,
            adhesionToWound: resultsCtx.adhesionToWound,
            combatingBiofilm: resultsCtx.combatingBiofilm,
            odorAbsorption: resultsCtx.odorAbsorption,
            canBeTrimmed: resultsCtx.canBeTrimmed
        }),
        headers: {
            'Content-Type' : 'application/json'
        }
    }).then(() => {})
        .catch((err) => {});
}

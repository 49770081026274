import React from 'react';
import './branding.css';

const BrandingComponent: React.FC = () => {
    return (
        <div className="branding__main-wrapper">

            <a className="branding__link" href="https://ptmr.info.pl/" target="_blank" rel="noreferrer">
                <div className="branding_img__wrapper">
                    <img className="branding_img-size"
                         alt="Polskie Towarzystwo Medycyny Rodzinnej"
                         src={require('./../../../../assets/graphics/logoPTMR.png')}/>

                </div>
            </a>

            <a className="branding__link" href="https://ptlr.org/" target="_blank" rel="noreferrer">
                <div className="branding_img__wrapper">
                    <img className="branding_img-size"
                         alt="Polskie Towarzystwo Leczenia Ran"
                         src={require('./../../../../assets/graphics/logoPTLR.png')}/>
                </div>
            </a>
        </div>
    );
}

export default BrandingComponent;

import React from 'react';
import * as Checkbox from '@radix-ui/react-checkbox';
import './yesnocheckbox.css';

type YesNoCheckboxProps = {
    id: string;
    onCheckHandler: (event: any) => void;
    checked: boolean;
    textValue: string;
    buttonType: string;
}

const YesNoCheckboxComponent: React.FC<YesNoCheckboxProps> = (props) => {
    return (
        <div className="yes-no-checkbox_main-wrapper">
            <Checkbox.Root id={props.id}
                           className={props.buttonType === 'right' ? 'yes-no-checkbox-root-right' : 'yes-no-checkbox-root-left'}
                           onCheckedChange={props.onCheckHandler}
                           checked={props.checked}>
                <Checkbox.Indicator className={props.buttonType === 'right' ? 'yes-no-checkbox-indicator-right' : 'yes-no-checkbox-indicator-left'}>
                    <div className={props.buttonType === 'right' ? 'yes-no-checkbox-label-right' : 'yes-no-checkbox-label-left'}>
                        {props.textValue}
                    </div>
                </Checkbox.Indicator>
                {!props.checked && (<div className="yes-no-checkbox-label-when-not-checked">{props.textValue}</div>)}
            </Checkbox.Root>
        </div>
    );
}

export default YesNoCheckboxComponent;

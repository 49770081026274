import * as _ from 'underscore';
export function separateStringIntoNumberArray(dataString: string): number[] {

    const whenToChangeArr: string[] = dataString.split(";");
    const whenToChange: number[] = [];

    whenToChangeArr.forEach(number => {
        if (number.trim() !== '') {
            whenToChange.push(Number.parseInt(number.trim()));
        }
    });

    return _.sortBy(whenToChange);
}

import {DressingModel} from '../model/dressing.model';
import {DayModel} from '../model/day.model';
import * as _ from 'underscore';

export function generateDaysList(dressingsFiltered: DressingModel[]): DayModel[] {

    const daysNumberArr: number[] = [];
    const daysFinalArr: DayModel[] = [];

    dressingsFiltered.forEach((dressing: DressingModel) => {
        dressing.whenToChange.forEach((number: number) => {
            daysNumberArr.push(number);
        });
    });

    const daysNumberArrUnique: number[] = _.unique(daysNumberArr);

    daysNumberArrUnique.forEach((value: number) => {
        if (value === 1) {
            daysFinalArr.push(
                {
                    id: value,
                    value: value.toString() + ' dzień',
                    disabled: false
                }
            )
        } else {
            daysFinalArr.push(
                {
                    id: value,
                    value: value.toString() + ' dni',
                    disabled: false
                }
            )
        }
    });
    return _.sortBy(daysFinalArr, 'id');
}

import {DressingModel} from '../model/dressing.model';
import * as _ from 'underscore';

export function generateActiveSubstances(dressingsFiltered: DressingModel[]): string[] {

    const activeSubstances: string[] = [];

    dressingsFiltered.forEach((dressing: DressingModel) => {
        dressing.activeSubstances.forEach((substance: string) => {
            if (substance !== '') {
                activeSubstances.push(substance.trim());
            }
        });
    });

    return _.sortBy(_.unique(activeSubstances));
}

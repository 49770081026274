import React from 'react';
import {Link} from 'react-router-dom';
import * as Checkbox from '@radix-ui/react-checkbox';
import {CheckIcon} from '@radix-ui/react-icons';
import {notNull} from '../../utils/null-check';
import './checkbox.css';

type CheckboxProps = {
    id: string;
    value: string;
    textValue?: string;
    showRegulationsValue?: boolean;
    onCheckHandler: (event: any) => void;
    checked: boolean;
    disabled?: boolean;
}

const CheckboxComponent: React.FC<CheckboxProps> = (props) => {
    return (
        <div className="checkbox_main-wrapper">
            {props.textValue &&
                <label className="checkbox-label" htmlFor={props.id}>
                    {props.textValue}
                </label>
            }
            <Checkbox.Root id={props.id}
                           className="checkbox-root"
                           value={props.value}
                           onCheckedChange={props.onCheckHandler}
                           checked={props.checked}
                           disabled={notNull(props.disabled) ? props.disabled : false}>
                <Checkbox.Indicator className="checkbox-indicator">
                    <CheckIcon />
                </Checkbox.Indicator>
            </Checkbox.Root>
            {props.showRegulationsValue &&
                <label className="checkbox-regulations-label" htmlFor={props.id}>
                    Zgadzam się z <Link className="checkbox_regulations-text" to={"/regulations"}>regulaminem</Link>.
                </label>
            }
        </div>
    );
}

export default CheckboxComponent;

import React from 'react';
import {useNavigate} from 'react-router-dom';
import './regulations.css';
import ButtonComponent from '../../shared/components/button/Button.component';

const Regulations: React.FC = () => {

    const navigate = useNavigate();

    const goBackHandler = () => {
        navigate('/');
    }

    return (
        <div className="regulations__main-wrapper">

            <div className="regulations_button__wrapper">
                <ButtonComponent id="resultsGoBackButton" value="<<" type="button" onClick={goBackHandler}/>
            </div>

            <div className="regulations__inner-wrapper">
                <h4>Licencja użytkowania</h4>
                <p className="regulations_bold-text">Podmiotem odpowiedzialnym za przygotowanie oprogramowania jest:</p>

                <p className="regulations__medutools-data">Wydawnictwo Interaktywne Medutools Sp. z o.o. z siedzibą w Krakowie (zwana dalej "Medutools")</p>
                <p className="regulations__medutools-data">ul. Wewnętrzna 5, 31-332 Kraków</p>
                <p className="regulations__medutools-data">
                    <a className="regulations_link"
                       href="https://medutools.pl/"
                       target="_blank"
                       rel="noreferrer">
                        www.medutools.pl
                    </a>
                </p>
                <p className="regulations__medutools-data">NIP: 945-217-70-78</p>
                <p className="regulations__medutools-data">REGON: 123018424</p>
                <p className="regulations__medutools-data">Numer KRS: 0000491636</p>


                <h4>Polityka prywatności, ochrona danych osobowych i pliki cookies:</h4>
                <p>
                    Polityka prywatności, ochrona danych osobowych i pliki cookies opublikowana jest w zakładce
                    serwisu <a className="regulations_link"
                               href="https://ptmr.info.pl"
                               target="_blank"
                               rel="noreferrer">ptmr.info.pl</a>,
                    to jest pod adresem: <a className="regulations_link"
                                            href="https://ptmr.info.pl/polityka-prywatnosci/"
                                            target="_blank"
                                            rel="noreferrer">https://ptmr.info.pl/polityka-prywatnosci/</a>
                </p>

                <p>
                    Wyszukiwarka oraz wszystkie zawarte w niej informacje są przeznaczone wyłącznie do użytku przez
                    przeszkolonych pracowników medycznych, którzy mają doświadczenie w leczeniu ran, zwłaszcza ran
                    przewlekłych, gwarantując iż zastosowana terapia i dobór opatrunku będą bezpieczne i skuteczne
                    dla konkretnego pacjenta.
                </p>

                <p>
                    Jeśli nie jesteś przeszkolonym specjalistą medycznym, nie korzystaj z wyszukiwarki. Wyszukiwarka
                    została opracowana jako narzędzie informacyjne wyłącznie w celu pomocy wyszkolonemu personelowi
                    medycznemu. Dane uzyskane przy użyciu wyszukiwarki nie są przeznaczone do bezpośredniego
                    zastosowania klinicznego. Zastosowanie kliniczne wszelkich danych uzyskanych za pomocą
                    wyszukiwarki leży wyłącznie w gestii użytkownika. Firma (ani żaden autor) nie gwarantuje
                    dokładności ani kompletności wyszukiwarki, a sugerowane opatrunki nie zastępują oceny klinicznej.
                </p>

                <p>
                    Wyszukiwarka jest udostępniana bezpłatnie wyłącznie w celach informacyjnych, a korzystanie z
                    niej jest uzależnione od zgody na warunki określone poniżej. Jeśli nie wyrażasz zgody na
                    którykolwiek z tych warunków, nie korzystaj z Wyszukiwarki.
                </p>

                <p>
                    UŻYWANIE WYSZUKIWARKI. Korzystając z Wyszukiwarki, oświadczasz i gwarantujesz, że jesteś
                    wyszkolonym specjalistą medycznym i potwierdzasz, że (a) dostawca Wyszukiwarki nie angażuje
                    się bezpośrednio w Twoją praktykę medyczną w jakiejkolwiek formie, a zatem nie ponosi żadnej
                    odpowiedzialności za wykorzystanie danych uzyskanych przez korzystanie z Wyszukiwarki,
                    (b) użytkownik ponosi odpowiedzialność za wykorzystanie i / lub wyniki wynikające z
                    wykorzystania jakichkolwiek danych uzyskanych za pomocą Wyszukiwarki oraz (c) użytkownik
                    zgadza się zwolnić Medutools jako dostawcę Wyszukiwarki ze wszelkich roszczeń, kosztów i
                    wydatków poniesionych przez użytkownika, a wynikających z korzystania z Wyszukiwarki.
                </p>

                <h4>WYŁĄCZENIE ODPOWIEDZIALNOŚCI Z TYTUŁU GWARANCJI.</h4>
                <p>
                    WYSZUKIWARKA JEST DOSTARCZANA BEZPŁATNIE BEZ JAKIEJKOLWIEK GWARANCJI, WYRAŹNEJ LUB DOROZUMIANEJ,
                    W TYM DOROZUMIANYCH GWARANCJI HANDLOWYCH I PRZYDATNOŚCI DO OKREŚLONEGO CELU. NIE GWARANTUJEMY JEJ
                    PEŁNEJ CZASOWEJ DOSTĘPNOŚCI. JAKIEKOLWIEK WADY LUB BŁĘDY NIE MOGĄ STANOWIĆ PODSTAWY ROSZCZEŃ WOBEC
                    DOSTAWCY, TO JEST MEDUTOOLS; WYNIKI KORZYSTANIA Z WYSZUKIWARKI MOGĄ NIE SPEŁNIĆ TWOICH WYMAGAŃ.
                </p>

                <h4>OGRANICZENIE ODPOWIEDZIALNOŚCI.</h4>
                <p>
                    W ŻADNYM WYPADKU MEDUTOOLS NIE PONOSI ODPOWIEDZIALNOŚCI W ODNIESIENIU DO WYSZUKIWARKI ZA
                    JEJ WYKORZYSTANIE W PRACY KLINICZNEJ.
                </p>

            </div>
        </div>
    );
}

export default Regulations;

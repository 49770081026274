export function saveSingleDressingToDatabase(resultsCtx: any): void {
    fetch('https://medutoolsbe.herokuapp.com/api/v1/dressings/single', {
        method: 'POST',
        body: JSON.stringify({
            userId: resultsCtx.userId,
            dressingId: resultsCtx.singleResultDressing.id,
            dressingName: resultsCtx.singleResultDressing.name,
            dressingProducer: resultsCtx.singleResultDressing.producer,
        }),
        headers: {
            'Content-Type' : 'application/json'
        }
    }).then(() => {})
        .catch((err) => {});
}

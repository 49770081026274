import React, {useContext, useEffect, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import {ResultsContext} from '../../../store/results-context';
import ButtonComponent from '../../shared/components/button/Button.component';
import SwitchComponent from '../../shared/components/switch/Switch.component';
import NumberOfDressingsComponent from '../../shared/components/number-of-dressings/NumberOfDressings.component';
import {saveChosenFiltersToDatabase} from '../../shared/utils/save-chosen-filters-to-database';
import {userShouldNotEnter} from '../../shared/utils/user-should-not-enter';

const FormDetailedInfo: React.FC = () => {

    window.scrollTo(1, 1);

    const resultsCtx = useContext(ResultsContext);
    const navigate = useNavigate();

    const [woundData, setWoundData] = useState<string>('');
    let navigateButtonDisabled = false;

    const woundDataHandler = (event: any) => {
        setWoundData(event?.target?.value);
    }

    const submitHandler = (event: React.FormEvent) => {
        event.preventDefault();
        if (woundData === '') {
            resultsCtx.setCheckedDressingsList([]);
            resultsCtx.setCheckedDressingsCounter(0);
            saveChosenFiltersToDatabase(resultsCtx);
            navigate('/results');
        } else {
            navigateButtonDisabled = true;
        }
    }

    const goBackHandler = () => {
        navigate('/');
    };

    useEffect(() => {
        if (userShouldNotEnter()) {
            navigate('/');
        }
    }, [navigate]);

    return (
        <div className="input-forms__wrapper">
            <form onSubmit={submitHandler} className="general_form__wrapper">

                <p className="general_form-header-text">Szczegółowe informacje</p>

                <div className="general_switch-input__wrapper display-none">
                    <input type="text"
                           value={woundData}
                           onChange={woundDataHandler} />
                </div>

                <div className="general_switch-input__wrapper">
                    <SwitchComponent id="adhesionToWound"
                                     description="Adhezja do rany"
                                     onCheckHandler={resultsCtx.onAdhesionToWoundClick}
                                     checked={resultsCtx.adhesionToWound}/>
                </div>

                <div className="general_switch-input__wrapper">
                    <SwitchComponent id="combatingBiofilm"
                                     description="Zwalczanie biofilmu"
                                     onCheckHandler={resultsCtx.onCombatingBiofilmClick}
                                     checked={resultsCtx.combatingBiofilm}/>
                </div>

                <div className="general_switch-input__wrapper">
                    <SwitchComponent id="odorAbsorption"
                                     description="Pochłanianie zapachu"
                                     onCheckHandler={resultsCtx.onOdorAbsorptionClick}
                                     checked={resultsCtx.odorAbsorption}/>
                </div>

                <div className="general_switch-input__wrapper">
                    <SwitchComponent id="canBeTrimmed"
                                     description="Docinanie opatrunku"
                                     onCheckHandler={resultsCtx.onCanBeTrimmedClick}
                                     checked={resultsCtx.canBeTrimmed}/>
                </div>

                <NumberOfDressingsComponent dressings={resultsCtx.dressingsFiltered} />

                <div className="form-two-buttons__wrapper">
                    <ButtonComponent id="formDetailedInfoGoBackButton" type="button" value="<<" onClick={goBackHandler}/>
                    <ButtonComponent id="formDetailedInfoSubmitButton" type="submit" disabled={navigateButtonDisabled} value=">>"/>
                </div>

            </form>
        </div>
    );
}

export default FormDetailedInfo;

import React, {useEffect, useMemo, useState} from 'react';
import './App.css';
import {Route, Routes} from 'react-router-dom';
import FormWoundType from './components/screens/form-wound-type/FormWoundType';
import FormDetailedInfo from './components/screens/form-detailed-info/FormDetailedInfo';
import Results from './components/screens/results/Results';
import SingleResult from './components/screens/single-result/SingleResult';
import DoubleResult from './components/screens/double-result/DoubleResult';
import TripleResult from './components/screens/triple-result/TripleResult';
import {v4 as uuidv4} from 'uuid';
import {DressingRawModel} from './components/shared/model/dressing-raw.model';
import dressingData from './assets/data/dressingsData.json';
import {convertDressingRawModel} from './components/shared/utils/convert-dressing-raw-model';
import {DressingPricingModel} from './components/shared/model/dressing-pricing.model';
import pricingData from './assets/data/pricingData.json';
import {createFilteredResults} from './components/shared/utils/create-filtered-results';
import {DayModel} from './components/shared/model/day.model';
import {generateDaysList} from './components/shared/utils/generate-days-list';
import {DressingModel} from './components/shared/model/dressing.model';
import {notNull} from './components/shared/utils/null-check';
import {getFullyFilteredResults} from './components/shared/utils/get-fully-filtered-results';
import {blockPreviousDays} from './components/shared/utils/block-previous-days';
import {blockFollowingDays} from './components/shared/utils/block-following-days';
import {ResultsModel} from './components/shared/model/results.model';
import {getApplicationType} from './components/shared/utils/get-application-type';
import {generateActiveSubstances} from './components/shared/utils/generate-active-substances';
import {generateDressingStructures} from './components/shared/utils/generate-dressing-structures';
import Regulations from './components/screens/regulations/Regulations';
import {ResultsContext} from './store/results-context';
import Login from './components/screens/login/Login';
import * as _ from 'underscore';

function App() {

    // TODO: wciąż brakuje mi przycisku resetu do checkboxów
    // TODO: Firefox ciasteczko z GTM
    // TODO: poprawiłabym jeszcze, żeby przy wczytywaniu pojedynczego wyniku po odświeżeniu strony dalej go miał, czyli w urlu dodać / coś tam

    const [userId, setUserId] = useState<string>(uuidv4());
    const userIdCached = useMemo(() => ({userId, setUserId}), [userId, setUserId]);

    const dressingDataRawFull: DressingRawModel[] = JSON.parse(JSON.stringify(dressingData));
    const dressingsFull = convertDressingRawModel(dressingDataRawFull);

    const pricingDataFull: DressingPricingModel[] = JSON.parse(JSON.stringify(pricingData));

    const [infectedWound, setInfectedWound] = useState<boolean>(false);
    const [burnedWound, setBurnedWound] = useState<boolean>(false);
    const [especiallyDryWound, setEspeciallyDryWound] = useState<boolean>(false);
    const [woundWithNecrosis, setWoundWithNecrosis] = useState<boolean>(false);
    const [maintainingMoistureEnvironment, setMaintainingMoistureEnvironment] = useState<boolean>(false);

    const [adhesionToWound, setAdhesionToWound] = useState<boolean>(false);
    const [combatingBiofilm, setCombatingBiofilm] = useState<boolean>(false);
    const [odorAbsorption, setOdorAbsorption] = useState<boolean>(false);
    const [canBeTrimmed, setCanBeTrimmed] = useState<boolean>(false);

    const [requiresActivationYes, setRequiresActivationYes] = useState<boolean>(false);
    const [requiresActivationNo, setRequiresActivationNo] = useState<boolean>(false);
    const [requiresSecondaryDressingYes, setRequiresSecondaryDressingYes] = useState<boolean>(false);
    const [requiresSecondaryDressingNo, setRequiresSecondaryDressingNo] = useState<boolean>(false);
    const [primary, setPrimary] = useState<boolean>(false);
    const [secondary, setSecondary] = useState<boolean>(false);

    const [selectedActiveSubstance, setSelectedActiveSubstance] = useState<string>('no-option');
    const [selectedStructure, setSelectedStructure] = useState<string>('no-option');
    const [selectedDayFrom, setSelectedDayFrom] = useState<string>('no-option');
    const [selectedDayTo, setSelectedDayTo] = useState<string>('no-option');

    const filteredResults = _.sortBy(createFilteredResults(dressingsFull, infectedWound, burnedWound, especiallyDryWound, woundWithNecrosis,
        maintainingMoistureEnvironment, adhesionToWound, combatingBiofilm, odorAbsorption, canBeTrimmed), "name");

    const [dressingsAdditionallyFiltered, setDressingsAdditionallyFiltered] = useState(filteredResults);

    const [daysFromList, setDaysFromList] = useState<DayModel[]>(generateDaysList(filteredResults));
    const [daysToList, setDaysToList] = useState<DayModel[]>(generateDaysList(filteredResults));

    const [activeFilters, setActiveFilters] = useState<boolean>(false);
    const [searchedText, setSearchedText] = useState<string>('');
    const [checkedDressingsList, setCheckedDressingsList] = useState<DressingModel[]>([]);
    const [compareDressingsButtonDisabled, setCompareDressingsButtonDisabled] = useState<boolean>(compareDressingsButtonDisabledDefault());
    const [checkedDressingsCounter, setCheckedDressingsCounter] = useState<number>(checkedDressingsList ? checkedDressingsList.length : 0);
    const [sortedAlphabetically, setSortedAlphabetically] = useState<boolean>(true);

    const [licenseNumber, setLicenseNumber] = useState<string>('');
    const [agreeToTerms, setAgreeToTerms] = useState<boolean>(false);
    const [profession, setProfession] = useState<string>('lekarzem');

    function compareDressingsButtonDisabledDefault(): boolean {
        return !(notNull(checkedDressingsList) &&
            (checkedDressingsList.length === 3 || checkedDressingsList.length === 2));
    }

    const noOptionsAreSelected = () => {
        return selectedActiveSubstance === 'no-option' && selectedStructure === 'no-option' &&
            selectedDayFrom === 'no-option' && selectedDayTo === 'no-option';
    }

    const infectedWoundClickHandler = (state: boolean) => {
        setInfectedWound(state);
        setSearchedText('');
        setDressingsAdditionallyFiltered(createFilteredResults(dressingsFull, state, burnedWound, especiallyDryWound, woundWithNecrosis,
            maintainingMoistureEnvironment, adhesionToWound, combatingBiofilm, odorAbsorption, canBeTrimmed));
        resetAdditionalFilters();
    };

    const burnedWoundClickHandler = (state: boolean) => {
        setBurnedWound(state);
        setSearchedText('');
        setDressingsAdditionallyFiltered(createFilteredResults(dressingsFull, infectedWound, state, especiallyDryWound, woundWithNecrosis,
            maintainingMoistureEnvironment, adhesionToWound, combatingBiofilm, odorAbsorption, canBeTrimmed));
        resetAdditionalFilters();
    };

    const especiallyDryWoundClickHandler = (state: boolean) => {
        setEspeciallyDryWound(state);
        setSearchedText('');
        setDressingsAdditionallyFiltered(createFilteredResults(dressingsFull, infectedWound, burnedWound, state, woundWithNecrosis,
            maintainingMoistureEnvironment, adhesionToWound, combatingBiofilm, odorAbsorption, canBeTrimmed));
        resetAdditionalFilters();
    };

    const woundWithNecrosisClickHandler = (state: boolean) => {
        setWoundWithNecrosis(state);
        setSearchedText('');
        setDressingsAdditionallyFiltered(createFilteredResults(dressingsFull, infectedWound, especiallyDryWound, especiallyDryWound, state,
            maintainingMoistureEnvironment, adhesionToWound, combatingBiofilm, odorAbsorption, canBeTrimmed));
        resetAdditionalFilters();
    };

    const maintainingMoistureEnvironmentClickHandler = (state: boolean) => {
        setMaintainingMoistureEnvironment(state);
        setSearchedText('');
        setDressingsAdditionallyFiltered(createFilteredResults(dressingsFull, infectedWound, burnedWound, especiallyDryWound, woundWithNecrosis,
            state, adhesionToWound, combatingBiofilm, odorAbsorption, canBeTrimmed));
        resetAdditionalFilters();
    };

    const adhesionToWoundClickHandler = (state: boolean) => {
        setAdhesionToWound(state);
        setSearchedText('');
        setDressingsAdditionallyFiltered(createFilteredResults(dressingsFull, infectedWound, burnedWound, especiallyDryWound, woundWithNecrosis,
            maintainingMoistureEnvironment, state, combatingBiofilm, odorAbsorption, canBeTrimmed));
        resetAdditionalFilters();
    };

    const combatingBiofilmClickHandler = (state: boolean) => {
        setCombatingBiofilm(state);
        setSearchedText('');
        setDressingsAdditionallyFiltered(createFilteredResults(dressingsFull, infectedWound, burnedWound, especiallyDryWound, woundWithNecrosis,
            maintainingMoistureEnvironment, adhesionToWound, state, odorAbsorption, canBeTrimmed));
        resetAdditionalFilters();
    };

    const odorAbsorptionClickHandler = (state: boolean) => {
        setOdorAbsorption(state);
        setSearchedText('');
        setDressingsAdditionallyFiltered(createFilteredResults(dressingsFull, infectedWound, burnedWound, especiallyDryWound, woundWithNecrosis,
            maintainingMoistureEnvironment, adhesionToWound, combatingBiofilm, state, canBeTrimmed));
        resetAdditionalFilters();
    };

    const canBeTrimmedClickHandler = (state: boolean) => {
        setCanBeTrimmed(state);
        setSearchedText('');
        setDressingsAdditionallyFiltered(createFilteredResults(dressingsFull, infectedWound, burnedWound, especiallyDryWound, woundWithNecrosis,
            maintainingMoistureEnvironment, adhesionToWound, combatingBiofilm, odorAbsorption, state));
        resetAdditionalFilters();
    };

    const filtersRequiresActivationYesClickHandler = (state: boolean) => {

        resetDressingCounterActions();

        if (!state) {
            if (!requiresActivationNo && !requiresSecondaryDressingYes && !requiresSecondaryDressingNo &&
                !primary && !secondary && noOptionsAreSelected()) {
                setActiveFilters(false);
            }

            setDressingsAdditionallyFiltered(
                getFullyFilteredResults(
                    resultsCtx.dressingsFiltered,
                    resultsCtx.searchedText,
                    resultsCtx.sortedAlphabetically,
                    false,
                    resultsCtx.filtersRequiresActivationNo,
                    resultsCtx.filtersRequiresSecondaryDressingYes,
                    resultsCtx.filtersRequiresSecondaryDressingNo,
                    resultsCtx.filtersPrimaryDressing,
                    resultsCtx.filtersSecondaryDressing,
                    resultsCtx.filtersSelectedActiveSubstance,
                    resultsCtx.filtersSelectedStructure,
                    resultsCtx.filtersSelectedDayFrom,
                    resultsCtx.filtersSelectedDayTo
                )
            );
            setRequiresActivationYes(false);
            return;

        } else {
            setActiveFilters(true);
            setRequiresActivationNo(false);

            setDressingsAdditionallyFiltered(
                getFullyFilteredResults(
                    resultsCtx.dressingsFiltered,
                    resultsCtx.searchedText,
                    resultsCtx.sortedAlphabetically,
                    true,
                    false,
                    resultsCtx.filtersRequiresSecondaryDressingYes,
                    resultsCtx.filtersRequiresSecondaryDressingNo,
                    resultsCtx.filtersPrimaryDressing,
                    resultsCtx.filtersSecondaryDressing,
                    resultsCtx.filtersSelectedActiveSubstance,
                    resultsCtx.filtersSelectedStructure,
                    resultsCtx.filtersSelectedDayFrom,
                    resultsCtx.filtersSelectedDayTo
                )
            );
            setRequiresActivationYes(true);
        }
    }

    const filtersRequiresActivationNoClickHandler = (state: boolean) => {

        resetDressingCounterActions();

        if (!state) {
            if (!requiresActivationYes && !requiresSecondaryDressingYes && !requiresSecondaryDressingNo
                && !primary && !secondary && noOptionsAreSelected()) {
                setActiveFilters(false);
            }

            setDressingsAdditionallyFiltered(
                getFullyFilteredResults(
                    resultsCtx.dressingsFiltered,
                    resultsCtx.searchedText,
                    resultsCtx.sortedAlphabetically,
                    resultsCtx.filtersRequiresActivationYes,
                    false,
                    resultsCtx.filtersRequiresSecondaryDressingYes,
                    resultsCtx.filtersRequiresSecondaryDressingNo,
                    resultsCtx.filtersPrimaryDressing,
                    resultsCtx.filtersSecondaryDressing,
                    resultsCtx.filtersSelectedActiveSubstance,
                    resultsCtx.filtersSelectedStructure,
                    resultsCtx.filtersSelectedDayFrom,
                    resultsCtx.filtersSelectedDayTo
                )
            );
            setRequiresActivationNo(false);
            return;

        } else {
            setRequiresActivationYes(false);
            setActiveFilters(true);

            setDressingsAdditionallyFiltered(
                getFullyFilteredResults(
                    resultsCtx.dressingsFiltered,
                    resultsCtx.searchedText,
                    resultsCtx.sortedAlphabetically,
                    false,
                    true,
                    resultsCtx.filtersRequiresSecondaryDressingYes,
                    resultsCtx.filtersRequiresSecondaryDressingNo,
                    resultsCtx.filtersPrimaryDressing,
                    resultsCtx.filtersSecondaryDressing,
                    resultsCtx.filtersSelectedActiveSubstance,
                    resultsCtx.filtersSelectedStructure,
                    resultsCtx.filtersSelectedDayFrom,
                    resultsCtx.filtersSelectedDayTo
                )
            );

        }

        setRequiresActivationNo(true);
    };

    const requiresSecondaryDressingYesClickHandler = (state: boolean) => {

        resetDressingCounterActions();

        if (!state) {
            if (!requiresActivationYes && !requiresActivationNo && !requiresSecondaryDressingNo &&
                !primary && !secondary && noOptionsAreSelected()) {
                setActiveFilters(false);
            }

            setDressingsAdditionallyFiltered(
                getFullyFilteredResults(
                    resultsCtx.dressingsFiltered,
                    resultsCtx.searchedText,
                    resultsCtx.sortedAlphabetically,
                    resultsCtx.filtersRequiresActivationYes,
                    resultsCtx.filtersRequiresActivationNo,
                    false,
                    resultsCtx.filtersRequiresSecondaryDressingNo,
                    resultsCtx.filtersPrimaryDressing,
                    resultsCtx.filtersSecondaryDressing,
                    resultsCtx.filtersSelectedActiveSubstance,
                    resultsCtx.filtersSelectedStructure,
                    resultsCtx.filtersSelectedDayFrom,
                    resultsCtx.filtersSelectedDayTo
                )
            );
            setRequiresSecondaryDressingYes(false);
            return;

        } else {
            setRequiresSecondaryDressingNo(false);
            setActiveFilters(true);

            setDressingsAdditionallyFiltered(
                getFullyFilteredResults(
                    resultsCtx.dressingsFiltered,
                    resultsCtx.searchedText,
                    resultsCtx.sortedAlphabetically,
                    resultsCtx.filtersRequiresActivationYes,
                    resultsCtx.filtersRequiresActivationNo,
                    true,
                    false,
                    resultsCtx.filtersPrimaryDressing,
                    resultsCtx.filtersSecondaryDressing,
                    resultsCtx.filtersSelectedActiveSubstance,
                    resultsCtx.filtersSelectedStructure,
                    resultsCtx.filtersSelectedDayFrom,
                    resultsCtx.filtersSelectedDayTo
                )
            );

            setRequiresSecondaryDressingYes(true);
        }
    };

    const requiresSecondaryDressingNoClickHandler = (state: boolean) => {

        resetDressingCounterActions();

        if (!state) {
            if (!requiresActivationYes && !requiresActivationNo && !requiresSecondaryDressingYes &&
                !primary && !secondary && noOptionsAreSelected()) {
                setActiveFilters(false);
            }

            setDressingsAdditionallyFiltered(
                getFullyFilteredResults(
                    resultsCtx.dressingsFiltered,
                    resultsCtx.searchedText,
                    resultsCtx.sortedAlphabetically,
                    resultsCtx.filtersRequiresActivationYes,
                    resultsCtx.filtersRequiresActivationNo,
                    resultsCtx.filtersRequiresSecondaryDressingYes,
                    false,
                    resultsCtx.filtersPrimaryDressing,
                    resultsCtx.filtersSecondaryDressing,
                    resultsCtx.filtersSelectedActiveSubstance,
                    resultsCtx.filtersSelectedStructure,
                    resultsCtx.filtersSelectedDayFrom,
                    resultsCtx.filtersSelectedDayTo
                )
            );

            setRequiresSecondaryDressingNo(false);
            return;

        } else {
            setRequiresSecondaryDressingYes(false);
            setActiveFilters(true);

            setDressingsAdditionallyFiltered(
                getFullyFilteredResults(
                    resultsCtx.dressingsFiltered,
                    resultsCtx.searchedText,
                    resultsCtx.sortedAlphabetically,
                    resultsCtx.filtersRequiresActivationYes,
                    resultsCtx.filtersRequiresActivationNo,
                    false,
                    true,
                    resultsCtx.filtersPrimaryDressing,
                    resultsCtx.filtersSecondaryDressing,
                    resultsCtx.filtersSelectedActiveSubstance,
                    resultsCtx.filtersSelectedStructure,
                    resultsCtx.filtersSelectedDayFrom,
                    resultsCtx.filtersSelectedDayTo
                )
            );

            setRequiresSecondaryDressingNo(true);
        }
    };

    const primaryClickHandler = (status: boolean) => {

        resetDressingCounterActions();

        if (!status) {
            if (!requiresActivationYes && !requiresActivationNo && !requiresSecondaryDressingYes &&
                !requiresSecondaryDressingNo && !secondary && noOptionsAreSelected()) {
                setActiveFilters(false);
            }

            setDressingsAdditionallyFiltered(
                getFullyFilteredResults(
                    resultsCtx.dressingsFiltered,
                    resultsCtx.searchedText,
                    resultsCtx.sortedAlphabetically,
                    resultsCtx.filtersRequiresActivationYes,
                    resultsCtx.filtersRequiresActivationNo,
                    resultsCtx.filtersRequiresSecondaryDressingYes,
                    resultsCtx.filtersRequiresSecondaryDressingNo,
                    false,
                    resultsCtx.filtersSecondaryDressing,
                    resultsCtx.filtersSelectedActiveSubstance,
                    resultsCtx.filtersSelectedStructure,
                    resultsCtx.filtersSelectedDayFrom,
                    resultsCtx.filtersSelectedDayTo
                )
            );

            setPrimary(false);
            return;

        } else {
            setSecondary(false);
            setActiveFilters(true);

            setDressingsAdditionallyFiltered(
                getFullyFilteredResults(
                    resultsCtx.dressingsFiltered,
                    resultsCtx.searchedText,
                    resultsCtx.sortedAlphabetically,
                    resultsCtx.filtersRequiresActivationYes,
                    resultsCtx.filtersRequiresActivationNo,
                    resultsCtx.filtersRequiresSecondaryDressingYes,
                    resultsCtx.filtersRequiresSecondaryDressingNo,
                    true,
                    false,
                    resultsCtx.filtersSelectedActiveSubstance,
                    resultsCtx.filtersSelectedStructure,
                    resultsCtx.filtersSelectedDayFrom,
                    resultsCtx.filtersSelectedDayTo
                )
            );

            setPrimary(true);
        }
    };

    const secondaryClickHandler = (status: boolean) => {

        resetDressingCounterActions();

        if (!status) {
            if (!requiresActivationYes && !requiresActivationNo && !requiresSecondaryDressingYes &&
                !requiresSecondaryDressingNo && !primary && noOptionsAreSelected()) {
                setActiveFilters(false);
            }

            setDressingsAdditionallyFiltered(
                getFullyFilteredResults(
                    resultsCtx.dressingsFiltered,
                    resultsCtx.searchedText,
                    resultsCtx.sortedAlphabetically,
                    resultsCtx.filtersRequiresActivationYes,
                    resultsCtx.filtersRequiresActivationNo,
                    resultsCtx.filtersRequiresSecondaryDressingYes,
                    resultsCtx.filtersRequiresSecondaryDressingNo,
                    resultsCtx.filtersPrimaryDressing,
                    false,
                    resultsCtx.filtersSelectedActiveSubstance,
                    resultsCtx.filtersSelectedStructure,
                    resultsCtx.filtersSelectedDayFrom,
                    resultsCtx.filtersSelectedDayTo
                )
            );

            setSecondary(false);
            return;

        } else {
            setPrimary(false);
            setActiveFilters(true);

            setDressingsAdditionallyFiltered(
                getFullyFilteredResults(
                    resultsCtx.dressingsFiltered,
                    resultsCtx.searchedText,
                    resultsCtx.sortedAlphabetically,
                    resultsCtx.filtersRequiresActivationYes,
                    resultsCtx.filtersRequiresActivationNo,
                    resultsCtx.filtersRequiresSecondaryDressingYes,
                    resultsCtx.filtersRequiresSecondaryDressingNo,
                    false,
                    true,
                    resultsCtx.filtersSelectedActiveSubstance,
                    resultsCtx.filtersSelectedStructure,
                    resultsCtx.filtersSelectedDayFrom,
                    resultsCtx.filtersSelectedDayTo
                )
            );

            setSecondary(true);
        }
    };

    const selectedActiveSubstanceClickHandler = (activeSubstance: string) => {

        resetDressingCounterActions();

        setActiveFilters(true);
        setSelectedActiveSubstance(activeSubstance);

        setDressingsAdditionallyFiltered(
            getFullyFilteredResults(
                resultsCtx.dressingsFiltered,
                resultsCtx.searchedText,
                resultsCtx.sortedAlphabetically,
                resultsCtx.filtersRequiresActivationYes,
                resultsCtx.filtersRequiresActivationNo,
                resultsCtx.filtersRequiresSecondaryDressingYes,
                resultsCtx.filtersRequiresSecondaryDressingNo,
                resultsCtx.filtersPrimaryDressing,
                resultsCtx.filtersSecondaryDressing,
                activeSubstance,
                resultsCtx.filtersSelectedStructure,
                resultsCtx.filtersSelectedDayFrom,
                resultsCtx.filtersSelectedDayTo
            )
        );
    };

    const selectedActiveSubstanceResetButtonHandler = () => {

        resetDressingCounterActions();

        if (!requiresActivationYes && !requiresActivationNo &&
            !requiresSecondaryDressingYes && !requiresSecondaryDressingNo &&
            !secondary && !primary &&
            selectedStructure === 'no-option' &&
            selectedDayFrom === 'no-option' && selectedDayTo === 'no-option') {
            setActiveFilters(false);
        }

        setSelectedActiveSubstance('no-option');

        setDressingsAdditionallyFiltered(
            getFullyFilteredResults(
                resultsCtx.dressingsFiltered,
                resultsCtx.searchedText,
                resultsCtx.sortedAlphabetically,
                resultsCtx.filtersRequiresActivationYes,
                resultsCtx.filtersRequiresActivationNo,
                resultsCtx.filtersRequiresSecondaryDressingYes,
                resultsCtx.filtersRequiresSecondaryDressingNo,
                resultsCtx.filtersPrimaryDressing,
                resultsCtx.filtersSecondaryDressing,
                'no-option',
                resultsCtx.filtersSelectedStructure,
                resultsCtx.filtersSelectedDayFrom,
                resultsCtx.filtersSelectedDayTo
            )
        );
    };

    const dressingStructureClickHandler = (structure: string) => {

        resetDressingCounterActions();

        setActiveFilters(true);
        setSelectedStructure(structure);

        setDressingsAdditionallyFiltered(
            getFullyFilteredResults(
                resultsCtx.dressingsFiltered,
                resultsCtx.searchedText,
                resultsCtx.sortedAlphabetically,
                resultsCtx.filtersRequiresActivationYes,
                resultsCtx.filtersRequiresActivationNo,
                resultsCtx.filtersRequiresSecondaryDressingYes,
                resultsCtx.filtersRequiresSecondaryDressingNo,
                resultsCtx.filtersPrimaryDressing,
                resultsCtx.filtersSecondaryDressing,
                resultsCtx.filtersSelectedActiveSubstance,
                structure,
                resultsCtx.filtersSelectedDayFrom,
                resultsCtx.filtersSelectedDayTo
            )
        );
    };

    const selectedStructureResetButtonHandler = () => {

        resetDressingCounterActions();

        if (!requiresActivationYes && !requiresActivationNo &&
            !requiresSecondaryDressingYes && !requiresSecondaryDressingNo &&
            !secondary && !primary &&
            selectedActiveSubstance === 'no-option' &&
            selectedDayFrom === 'no-option' && selectedDayTo === 'no-option') {
            setActiveFilters(false);
        }

        setSelectedStructure('no-option');

        setDressingsAdditionallyFiltered(
            getFullyFilteredResults(
                resultsCtx.dressingsFiltered,
                resultsCtx.searchedText,
                resultsCtx.sortedAlphabetically,
                resultsCtx.filtersRequiresActivationYes,
                resultsCtx.filtersRequiresActivationNo,
                resultsCtx.filtersRequiresSecondaryDressingYes,
                resultsCtx.filtersRequiresSecondaryDressingNo,
                resultsCtx.filtersPrimaryDressing,
                resultsCtx.filtersSecondaryDressing,
                resultsCtx.filtersSelectedActiveSubstance,
                'no-option',
                resultsCtx.filtersSelectedDayFrom,
                resultsCtx.filtersSelectedDayTo
            )
        );
    };

    const selectedDayFromClickHandler = (dayFrom: string) => {

        resetDressingCounterActions();

        setDaysToList(blockPreviousDays(Number.parseInt(dayFrom), generateDaysList(filteredResults)));
        setActiveFilters(true);
        setSelectedDayFrom(dayFrom);

        setDressingsAdditionallyFiltered(
            getFullyFilteredResults(
                resultsCtx.dressingsFiltered,
                resultsCtx.searchedText,
                resultsCtx.sortedAlphabetically,
                resultsCtx.filtersRequiresActivationYes,
                resultsCtx.filtersRequiresActivationNo,
                resultsCtx.filtersRequiresSecondaryDressingYes,
                resultsCtx.filtersRequiresSecondaryDressingNo,
                resultsCtx.filtersPrimaryDressing,
                resultsCtx.filtersSecondaryDressing,
                resultsCtx.filtersSelectedActiveSubstance,
                resultsCtx.filtersSelectedStructure,
                dayFrom,
                resultsCtx.filtersSelectedDayTo
            )
        );
    };

    const selectedDayToClickHandler = (dayTo: string) => {

        resetDressingCounterActions();

        setDaysFromList(blockFollowingDays(Number.parseInt(dayTo), generateDaysList(filteredResults)));
        setActiveFilters(true);
        setSelectedDayTo(dayTo);

        setDressingsAdditionallyFiltered(
            getFullyFilteredResults(
                resultsCtx.dressingsFiltered,
                resultsCtx.searchedText,
                resultsCtx.sortedAlphabetically,
                resultsCtx.filtersRequiresActivationYes,
                resultsCtx.filtersRequiresActivationNo,
                resultsCtx.filtersRequiresSecondaryDressingYes,
                resultsCtx.filtersRequiresSecondaryDressingNo,
                resultsCtx.filtersPrimaryDressing,
                resultsCtx.filtersSecondaryDressing,
                resultsCtx.filtersSelectedActiveSubstance,
                resultsCtx.filtersSelectedStructure,
                resultsCtx.filtersSelectedDayFrom,
                dayTo
            )
        );
    };

    const selectedDaysResetButtonHandler = () => {

        resetDressingCounterActions();

        setSelectedDayFrom('no-option');
        setSelectedDayTo('no-option');

        if (!requiresActivationYes && !requiresActivationNo &&
            !requiresSecondaryDressingYes && !requiresSecondaryDressingNo &&
            !secondary && !primary &&
            selectedActiveSubstance === 'no-option' && selectedStructure === 'no-option') {
            setActiveFilters(false);
        }

        setDaysFromList(generateDaysList(filteredResults));
        setDaysToList(generateDaysList(filteredResults));

        setDressingsAdditionallyFiltered(
            getFullyFilteredResults(
                resultsCtx.dressingsFiltered,
                resultsCtx.searchedText,
                resultsCtx.sortedAlphabetically,
                resultsCtx.filtersRequiresActivationYes,
                resultsCtx.filtersRequiresActivationNo,
                resultsCtx.filtersRequiresSecondaryDressingYes,
                resultsCtx.filtersRequiresSecondaryDressingNo,
                resultsCtx.filtersPrimaryDressing,
                resultsCtx.filtersSecondaryDressing,
                resultsCtx.filtersSelectedActiveSubstance,
                resultsCtx.filtersSelectedStructure,
                'no-option',
                'no-option'
            )
        );
    };

    const activeFiltersClickHandler = (status: boolean) => {
        setActiveFilters(status);
    };

    const dressingsAdditionallyFilteredHandler = (dressings: DressingModel[]) => {
        setDressingsAdditionallyFiltered(dressings);
    };

    const searchedTextHandler = (searchedText: string) => {
        resetDressingCounterActions();
        setSearchedText(searchedText);
    }

    const checkedDressingsListHandler = (dressings: DressingModel[]) => {
        setCheckedDressingsList(dressings);
    }

    const compareDressingsButtonDisabledHandler = (state: boolean) => {
        setCompareDressingsButtonDisabled(state);
    }

    const checkedDressingsCounterHandler = (checked: number) => {
        setCheckedDressingsCounter(checked);
    }

    const sortedAlphabeticallyHandler = (state: boolean) => {
        setSortedAlphabetically(state);
    }

    const licenceNumberChangeHandler = (value: string) => {
        setLicenseNumber(value);
    }

    const agreeToTermsChangeHandler = (state: boolean) => {
        setAgreeToTerms(state);
    }

    const professionChangeHandler = (value: string) => {
        setProfession(value)
    }

    function resetAdditionalFilters(): void {
        setRequiresActivationYes(false);
        setRequiresActivationNo(false);
        setRequiresSecondaryDressingYes(false);
        setRequiresSecondaryDressingNo(false);
        setPrimary(false);
        setSecondary(false)
        setSelectedActiveSubstance('no-option');
        setSelectedStructure('no-option');
        setSelectedDayFrom('no-option');
        setSelectedDayTo('no-option');
        setActiveFilters(false);
        setCheckedDressingsCounter(0);
    }

    function resetDressingCounterActions(): void {
        setCheckedDressingsCounter(0);
        setCheckedDressingsList([]);
        setCompareDressingsButtonDisabled(true);
    }

    function userIsValidated(): boolean {
        return localStorage.getItem('agreeToTerms') === 'true' &&
               localStorage.getItem('licenseIsValid') === 'true' &&
               localStorage.getItem('loginButtonClicked') === 'true';
    }

    useEffect(() => {}, [
        licenseNumber,
        agreeToTerms,
        profession,
        infectedWound,
        burnedWound,
        especiallyDryWound,
        woundWithNecrosis,
        maintainingMoistureEnvironment,
        adhesionToWound,
        combatingBiofilm,
        odorAbsorption,
        canBeTrimmed,
        requiresActivationYes,
        requiresActivationNo,
        requiresSecondaryDressingYes,
        requiresSecondaryDressingNo,
        selectedActiveSubstance,
        selectedDayFrom,
        selectedDayTo,
        activeFilters,
        primary,
        secondary,
        dressingsFull,
        dressingDataRawFull,
        dressingsAdditionallyFiltered,
        daysFromList,
        daysToList,
        searchedText,
        checkedDressingsList,
        compareDressingsButtonDisabled,
        checkedDressingsCounter
    ]);


    const resultsCtx: ResultsModel = {
        userId: userIdCached.userId,
        profession: profession,
        onProfessionChange: professionChangeHandler,
        licenseNumber: licenseNumber,
        onLicenseNumberChange: licenceNumberChangeHandler,
        agreeToTerms: agreeToTerms,
        onAgreeToTermsChange: agreeToTermsChangeHandler,
        applicationType: getApplicationType(),
        infectedWound: infectedWound,
        onInfectedWoundClick: infectedWoundClickHandler,
        burnedWound: burnedWound,
        onBurnedWoundClick: burnedWoundClickHandler,
        especiallyDryWound: especiallyDryWound,
        onEspeciallyDryWoundClick: especiallyDryWoundClickHandler,
        woundWithNecrosis: woundWithNecrosis,
        onWoundWithNecrosisClick: woundWithNecrosisClickHandler,
        maintainingMoistureEnvironment: maintainingMoistureEnvironment,
        onMaintainingMoistureEnvironmentClick: maintainingMoistureEnvironmentClickHandler,
        adhesionToWound: adhesionToWound,
        onAdhesionToWoundClick: adhesionToWoundClickHandler,
        combatingBiofilm: combatingBiofilm,
        onCombatingBiofilmClick: combatingBiofilmClickHandler,
        odorAbsorption: odorAbsorption,
        onOdorAbsorptionClick: odorAbsorptionClickHandler,
        canBeTrimmed: canBeTrimmed,
        onCanBeTrimmedClick: canBeTrimmedClickHandler,
        filtersRequiresActivationYes: requiresActivationYes,
        onFiltersRequiresActivationYesClick: filtersRequiresActivationYesClickHandler,
        filtersRequiresActivationNo: requiresActivationNo,
        onFiltersRequiresActivationNoClick: filtersRequiresActivationNoClickHandler,
        filtersRequiresSecondaryDressingYes: requiresSecondaryDressingYes,
        onFiltersRequiresSecondaryYesClick: requiresSecondaryDressingYesClickHandler,
        filtersRequiresSecondaryDressingNo: requiresSecondaryDressingNo,
        onFiltersRequiresSecondaryDressingNoClick: requiresSecondaryDressingNoClickHandler,
        filtersPrimaryDressing: primary,
        onFiltersPrimaryDressingClick: primaryClickHandler,
        filtersSecondaryDressing: secondary,
        onFiltersSecondaryDressingClick: secondaryClickHandler,
        filtersSelectedActiveSubstance: selectedActiveSubstance,
        onFiltersSelectedActiveSubstanceClick: selectedActiveSubstanceClickHandler,
        onSelectedActiveSubstanceResetButtonClick: selectedActiveSubstanceResetButtonHandler,
        filtersSelectedStructure: selectedStructure,
        onFiltersSelectedStructure: dressingStructureClickHandler,
        onSelectedStructureResetButtonClick: selectedStructureResetButtonHandler,
        filtersSelectedDayFrom: selectedDayFrom,
        onFiltersSelectedDayFromClick: selectedDayFromClickHandler,
        filtersSelectedDayTo: selectedDayTo,
        onFiltersSelectedDayToClick: selectedDayToClickHandler,
        onSelectedDaysResetButtonClick: selectedDaysResetButtonHandler,
        activeFilters: activeFilters,
        setActiveFilters: activeFiltersClickHandler,
        compareDressingsButtonDisabled: compareDressingsButtonDisabled,
        setCompareDressingsButtonDisabled: compareDressingsButtonDisabledHandler,
        dressingsFull: dressingsFull,
        dressingPricingDataFull: pricingDataFull,
        dressingsFiltered: filteredResults,
        searchedText: searchedText,
        onSearchedTextChange: searchedTextHandler,
        dressingsAdditionallyFiltered: dressingsAdditionallyFiltered,
        setDressingsAdditionallyFiltered: dressingsAdditionallyFilteredHandler,
        activeSubstancesList: generateActiveSubstances(filteredResults),
        dressingStructuresList: generateDressingStructures(filteredResults),
        singleResultDressing: null as any,
        checkedDressingsList: checkedDressingsList,
        setCheckedDressingsList: checkedDressingsListHandler,
        checkedDressingsCounter: checkedDressingsCounter,
        setCheckedDressingsCounter: checkedDressingsCounterHandler,
        sortedAlphabetically: sortedAlphabetically,
        setSortedAlphabetically: sortedAlphabeticallyHandler
    };

    return (
        <ResultsContext.Provider value={resultsCtx}>
            <Routes>
                <Route path="/" element={ userIsValidated() ? <FormWoundType /> : <Login/> } />
                <Route path="/form-wound-type" element={<FormWoundType />} />
                <Route path="/form-detailed-info" element={<FormDetailedInfo/>} />
                <Route path="/results" element={<Results daysFrom={daysFromList} daysTo={daysToList}/>} />
                <Route path="/single" element={<SingleResult/>} />
                <Route path="/double" element={<DoubleResult/>} />
                <Route path="/triple" element={<TripleResult/>} />
                <Route path="/regulations" element={<Regulations/>} />
                <Route path="*" element={ userIsValidated() ? <FormWoundType /> : <Login/> } />
            </Routes>
        </ResultsContext.Provider>
    );
}

export default App;

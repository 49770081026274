import {DressingModel} from '../model/dressing.model';


export function dressingContainsSearchedText(dressing: DressingModel, searchedText: string): boolean {
    return containsPhrase(dressing.name, searchedText) ||
        containsPhrase(dressing.producer, searchedText) ||
        containsPhrase(dressing.structureFull, searchedText) ||
        containsPhrase(dressing.action, searchedText) ||
        arrayContainsPhrase(dressing.structures, searchedText) ||
        arrayContainsPhrase(dressing.tags, searchedText) ||
        arrayContainsPhrase(dressing.activeSubstances, searchedText);
}

function containsPhrase(word: string, phrase: string): boolean {
    return word.toLowerCase().indexOf(phrase.toLowerCase()) !== -1;
}

function arrayContainsPhrase(words: string[], phrase: string): boolean {
    return containsPhrase(words.join(';'), phrase);
}

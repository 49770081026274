import React, {useContext, useEffect, useState} from 'react';
import './resultsalltable.css';
import {TriangleDownIcon, TriangleUpIcon} from '@radix-ui/react-icons';
import CheckboxComponent from '../checkbox/Checkbox.component';
import DressingDetailedInfoComponent from '../dressing-detailed-info/DressingDetailedInfo.component';
import WordsVerticalComponent from '../words-vertical/WordsVertical.component';
import {ResultsContext} from '../../../../store/results-context';
import {getApplicationType} from '../../utils/get-application-type';
import {ApplicationType} from '../../model/ApplicationType';
import SeeMoreComponent from '../see-more/SeeMore.component';
import {DressingModel} from '../../model/dressing.model';
import ButtonComponent from '../button/Button.component';
import {useNavigate} from 'react-router-dom';
import * as _ from 'underscore';
import {saveDressingsComparedToDatabase} from '../../utils/save-dressings-compared-to-database';

const ResultsAllTableComponent: React.FC = () => {

    const navigate = useNavigate();
    const resultsCtx = useContext(ResultsContext);
    const [checkboxValue, setCheckboxValue] = useState<boolean>(true);

    let compareDressingsBtnDisabled: boolean = true;

    const maximumNumberOfCheckedDressings: number = getApplicationType() === ApplicationType.REGULAR ? 2 : 1;

    function getDressingById(dressingId: string): DressingModel {
        return resultsCtx.dressingsAdditionallyFiltered.filter((dressing: DressingModel) => dressing.id === dressingId)[0];
    }

    const compareButtonHandler = () => {
        saveDressingsComparedToDatabase(resultsCtx);
        if (resultsCtx.checkedDressingsList.length === 3) {
            navigate('/triple');
        }
        if (resultsCtx.checkedDressingsList.length === 2) {
            navigate('/double');
        }
    };

    function blockOtherCheckboxes(state: boolean, dressingId: string): void {

        resultsCtx.dressingsAdditionallyFiltered.forEach(dressing => {

            let localCounter: number = resultsCtx.checkedDressingsCounter;

            if (state) {
                localCounter = resultsCtx.checkedDressingsCounter + 1;
                resultsCtx.setCheckedDressingsCounter(localCounter);

                if (dressingId === dressing.id) {
                    resultsCtx.checkedDressingsList.push(getDressingById(dressingId));
                }

                compareDressingsBtnDisabled = resultsCtx.checkedDressingsList.length === 1;

                if (localCounter <= maximumNumberOfCheckedDressings) {
                    dressing.disabled = false;
                } else if (localCounter > maximumNumberOfCheckedDressings && dressing.id !== dressingId &&
                    !_.contains(resultsCtx.checkedDressingsList, dressing)) {
                    dressing.disabled = true;
                }
            } else {

                localCounter = localCounter - 1;
                resultsCtx.setCheckedDressingsCounter(localCounter);

                const dressings = resultsCtx.checkedDressingsList.filter(dressing => dressing.id !== dressingId);
                resultsCtx.setCheckedDressingsList(dressings);

                compareDressingsBtnDisabled = dressings.length === 0 || dressings.length === 1;

                if (localCounter <= maximumNumberOfCheckedDressings) {
                    dressing.disabled = false;
                } else if (localCounter > maximumNumberOfCheckedDressings && dressing.id !== dressingId) {
                    dressing.disabled = true;
                }
            }
        });
    }

    const sortByNameDescendingHandler = () => {
        resultsCtx.setSortedAlphabetically(false);
        resultsCtx.setDressingsAdditionallyFiltered(_.sortBy(resultsCtx.dressingsAdditionallyFiltered, "name").reverse());
    }

    const sortByNameAscendingHandler = () => {
        resultsCtx.setSortedAlphabetically(true);
        resultsCtx.setDressingsAdditionallyFiltered(_.sortBy(resultsCtx.dressingsAdditionallyFiltered, "name"));
    }

    useEffect(() => {
    }, [
        resultsCtx,
        checkboxValue
    ]);

    return (
        <div className="results-all-table_main-wrapper">

            {resultsCtx.dressingsAdditionallyFiltered.length > 1 &&
                <div
                    className="results-all-table_left-right__wrapper results-all-table_border-bottom display-on-big-screen">


                    <div className="results-all-table_left__wrapper">
                        <div className="results-all-table_content-name__header-wrapper">
                            <span className="results-all-table_header__text">Nazwa</span>
                            <div className="results-all-table_triangles__wrapper">
                                <div className="results-all-table_triangle__wrapper">
                                    <TriangleDownIcon onClick={sortByNameDescendingHandler}/>
                                </div>
                                <div className="results-all-table_triangle__wrapper">
                                    <TriangleUpIcon onClick={sortByNameAscendingHandler}/>
                                </div>
                            </div>
                        </div>
                        <div className="results-all-table_content-action__wrapper">
                            <span className="results-all-table_header__text">Działanie</span>
                        </div>
                        <div className="results-all-table_content-active-substances__wrapper">
                            <span className="results-all-table_header__text">Substancje aktywne</span>
                        </div>
                    </div>

                    <div className="results-all-table_right__wrapper">
                        <div className="results-all-table_content_more__wrapper"></div>
                        <div className="results-all-table_content-active-substances__wrapper">
                            {resultsCtx.dressingsAdditionallyFiltered.length >= 2 &&
                                <span className="results-all-table_header__text">Porównaj</span>
                            }
                        </div>
                    </div>
                </div>
            }

            <div className="results-all-table_content__wrapper">

                <div className="results-all-table_compare-dressings__button">
                    {resultsCtx.dressingsAdditionallyFiltered.length > 1 &&
                        <ButtonComponent id="compareDressingsButton"
                                         value="porównaj opatrunki >>"
                                         type="button"
                                         onClick={compareButtonHandler}
                                         disabled={resultsCtx.compareDressingsButtonDisabled}
                                         className='compare-dressings-button'/>
                    }
                </div>
                {resultsCtx.dressingsAdditionallyFiltered.length > 1 &&
                    <div className="results-all-table_white-line"></div>
                }

                {resultsCtx.dressingsAdditionallyFiltered.map((dressing: DressingModel) => (
                    <div key={dressing.id} className="results-all-table_content__inner-wrapper">
                        <div className="results-all-table_left-right__wrapper">
                            <div className="results-all-table_left__wrapper">
                                <div className="results-all-table_content-name__wrapper">
                                    <p className="results-all-table_dressing-name__text">{dressing.name}</p>
                                    <p className="results-all-table_header__text">{dressing.producer}</p>
                                </div>
                                <div className="results-all-table_content-action__wrapper">
                                    <p className="results-all-table_dressing-description__text">
                                        {dressing.action}
                                    </p>
                                </div>
                                <div className="results-all-table_content-active-substances__wrapper">
                                    <p className="results-all-table_section-headers__text">Substancje aktywne:</p>
                                    <WordsVerticalComponent data={dressing.activeSubstances}/>
                                </div>
                            </div>
                            <div className="results-all-table_right__wrapper">
                                <div className="results-all-table_content_more__wrapper">
                                    <SeeMoreComponent id={dressing.id}/>
                                </div>
                                <div className="results-all-table_content_checkbox__wrapper">
                                    {resultsCtx.dressingsAdditionallyFiltered.length >= 2 &&
                                        <CheckboxComponent id={dressing.id}
                                                           value={dressing.id}
                                                           disabled={dressing.disabled}
                                                           onCheckHandler={(value: boolean) => {
                                                               setCheckboxValue((prevState: boolean) => !prevState);
                                                               blockOtherCheckboxes(value, dressing.id);
                                                               resultsCtx.setCompareDressingsButtonDisabled(compareDressingsBtnDisabled);
                                                               dressing.checked = value;
                                                           }
                                                           }
                                                           checked={dressing.checked !== undefined ? dressing.checked : checkboxValue} />
                                    }
                                </div>
                            </div>
                        </div>
                        <div className="results-all-table_collapsible__wrapper">
                            <DressingDetailedInfoComponent dressing={dressing}/>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
}

export default ResultsAllTableComponent;

import React, {useContext, useEffect} from 'react';
import './doubleresult.css';
import ResultsDoubleTableComponent from '../../shared/components/results-double-table/ResultsDoubleTable.component';
import ButtonComponent from '../../shared/components/button/Button.component';
import {useNavigate} from 'react-router-dom';
import {ResultsContext} from '../../../store/results-context';
import {userShouldNotEnter} from '../../shared/utils/user-should-not-enter';

const DoubleResult: React.FC = () => {

    window.scrollTo(1, 1);

    const navigate = useNavigate();
    const resultsCtx = useContext(ResultsContext);

    const goBackHandler = () => {
        navigate('/results');
    };

    useEffect(() => {
        if (userShouldNotEnter()) {
            navigate('/');
        }

        if (resultsCtx.checkedDressingsList.length !== 2) {
            navigate('/');
        }
    }, [navigate, resultsCtx]);

    return (
        <div className="double-result__wrapper">
            <div className="double-result__content">

                <div className="double-result_button__wrapper">
                    <ButtonComponent id="doubleResultGoBackButton" value="<<" type="button" onClick={goBackHandler}/>
                </div>

                <div className="double-result_table__wrapper">
                    <ResultsDoubleTableComponent />
                </div>
            </div>
        </div>
    );
}

export default DoubleResult;
